// RankingDisplay.tsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./App.css";
import { useTranslation } from 'react-i18next';

interface RankingEntry {
  rank: number;
  owner: string;
  manager_name: string;
  total_score: number;
}

interface RankingData {
  ranking: RankingEntry[];
  userPosition: number;
  distinctOwnersCount: number; // Add distinct owners count to the interface
}

interface RankingDisplayProps {
  selectedGameweek: string;
  onUserPositionChange: (position: number) => void; // Callback for user position
  onDistinctOwnersCountChange: (count: number) => void; // Callback for distinct owners count
}

const RankingDisplay: React.FC<RankingDisplayProps> = ({
  selectedGameweek,
  onUserPositionChange,
  onDistinctOwnersCountChange,
}) => {
  const [rankingData, setRankingData] = useState<RankingData | null>(null);
  const walletAddress = localStorage.getItem('walletAddress') || '';
  const { t } = useTranslation();

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  useEffect(() => {
    const fetchRanking = async () => {
      try {
        const response = await axios.get(`${linkUrl}/wp-json/custom/v1/get-ranking`, {
          params: {
            selectedGameweek,
            walletAddress,
          },
        });
        setRankingData(response.data);

        // Update user position and distinct owners count in parent component
        onUserPositionChange(response.data.userPosition);
        onDistinctOwnersCountChange(response.data.distinctOwnersCount);
      } catch (error) {
        console.error('Error fetching ranking:', error);
      }
    };

    if (selectedGameweek && walletAddress) {
      fetchRanking();
    }
  }, [selectedGameweek, walletAddress, onUserPositionChange, onDistinctOwnersCountChange]);

  return (
    <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      {rankingData ? (
        <>
          <div className="rankingDisplay">
            {rankingData.ranking.map((entry) => (
              <span key={entry.rank} className="rankingEntry">
                <span className="rankingRank">{entry.rank}</span>
                <span className="rankingOwner">{entry.owner === walletAddress ? t('You') : entry.manager_name}</span>
                <span className="rankingScore">{entry.total_score} {t('points')}</span>
              </span>
            ))}
          </div>
        </>
      ) : (
        <p>Loading ranking data...</p>
      )}
    </div>
  );
};

export default RankingDisplay;
