import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar from './locales/ar.json';
import cn from './locales/cn.json';
import de from './locales/de.json';
import en from './locales/en.json';
import fr from './locales/fr.json';
import he from './locales/he.json';
import it from './locales/it.json';
import jp from './locales/jp.json';
import kr from './locales/kr.json';
import nl from './locales/nl.json';
import pt from './locales/pt.json';
import ru from './locales/ru.json';
import sp from './locales/sp.json';

// Initialize i18n
i18n.use(initReactI18next).init({
  resources: {
ar: { translation: ar },
cn: { translation: cn },
de: { translation: de },
en: { translation: en },
fr: { translation: fr },
he: { translation: he },
it: { translation: it },
jp: { translation: jp },
kr: { translation: kr },
nl: { translation: nl },
pt: { translation: pt },
ru: { translation: ru },
sp: { translation: sp },
  },
  lng: localStorage.getItem('pxlLanguage') || 'en', // Set language from localStorage or default to English
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false, // React already handles escaping
  },
});

export default i18n;
