
import './App.css';
import { isMobile, Link, ImmutableXClient, ImmutableMethodResults } from '@imtbl/imx-sdk';
import axios from 'axios'; // Import the axios library
import React, { useEffect, useState, useCallback, useMemo, Suspense, lazy, memo, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { setOpenAssetModalFunc, openAssetModal } from './AssetModalUtils'; // Import utility functions
import { setOpenOrderModalFunc, openOrderModal } from './OrderModalUtils'; 
import { setOpenSameCardsModalFunc, openSameCardsModal } from './ModalUtils';
import { setHandleRefreshFunc } from './RefreshUtils'; // Import the function
import {AssetResponse, TalentCard, Order, Asset} from './types';
import { setPageViewModalFunc } from './PageViewModalUtils';
import { openPageViewModal } from './PageViewModalUtils';
import _ from 'lodash';
import { throttle } from 'lodash';
import { useTranslation } from 'react-i18next';
import './i18n'; 
import RankingDisplay from './rankingDisplay';

require('dotenv').config();

const OptimizedHeaderLazy = memo(lazy(() => import('./OptimizedHeader')));
const OrderModalLazy = memo(lazy(() => import('./OrderModal')));
const AssetModalLazy = memo(lazy(() => import('./AssetModal')));
const ExperienceDisplayLazy = memo(lazy(() => import('./experienceDisplay')));
const ModalSameCardsLazy = memo(lazy(() => import('./ModalSameCards')));
const PageViewModalLazy = memo(lazy(() => import('./pageViewModal')));
const ChartValueTeamLazy = memo(lazy(() => import('./chartValueTeam')));
const FooterLazy = memo(lazy(() => import('./footer')));
const DailyBalanceChartLazy = memo(lazy(() => import('./chartDailyBalance')));
const FilterModalLazy = memo(lazy(() => import('./filterModal')));
const TalentSignupModalLazy = memo(lazy(() => import('./talentSignupModal')));
const WelcomeModalLazy = memo(lazy(() => import('./welcomeModal')));
const LastNewsDisplayLazy = memo(lazy(() => import('./lastNewsDisplay')));
const TeamTurnoverLazy = memo(lazy(() => import('./teamTurnover')));
const EventCountdownLazy = memo(lazy(() => import('./countdown')));
const KeepLineupCheckedLazy = memo(lazy(() => import('./keepLineupChecked')));
const PastLineupsLazy = memo(lazy(() => import('./pastLineups')));

type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};

interface SelectedAsset {
  asset: Asset;
  order_id: string;
  locationPath: string; // Adjust as needed if Location is not the correct type
}

async function fetchTalentCardProp(tokenAddress: string, tokenId: string) {
  try {

    const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`) 
      ? `https://${stagingKey}.peaxel.me` 
      : 'https://peaxel.me';


    const url = new URL(`${linkUrl}/wp-json/custom/v1/talent-card-prop/`);
    url.search = new URLSearchParams({ token_address: tokenAddress, token_id: tokenId }).toString();

    const response = await fetch(url.toString());
    if (!response.ok) {
      console.error('Error fetching talent card data:', response.statusText);
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching talent card data:', error);
    return null;
  }
}

async function balancePush(walletBalancePush: any) {
  const walletAddressPush = localStorage.getItem('walletAddress') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletNetworkPush = localStorage.getItem('walletNetwork') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletStarkPush = localStorage.getItem('walletStark') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletEmailPush = localStorage.getItem('walletEmail') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletProviderPush = localStorage.getItem('walletPreference') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletCurrencyPush = localStorage.getItem('pxlCurrency') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletLanguagePush = localStorage.getItem('pxlLanguage') || ''; // Retrieve the 'walletAddress' value from local storage

  window.postMessage(
    {
      type: 'UPDATE_WALLET_ADDRESS',
      walletAddress: walletAddressPush,
      walletNetwork: walletNetworkPush,
      walletStark: walletStarkPush,
      walletEmail: walletEmailPush || '',
      walletPreference: walletProviderPush,
      pxlCurrency: walletCurrencyPush,
      pxlBalance: walletBalancePush,
      pxlLanguage: walletLanguagePush,
    },
    window.location.origin // Only send the message to the same origin
  );
}


async function displayAllAuctions(
  assets: Asset[],
  client: ImmutableXClient | null,
  refresh: number, 
  talentName: string,
  state: string,
  todisplay: string,
  locationPage: string,
  modal: string,
  t: (key: string, options?: any) => string, // Add t as a parameter
  filterOptions: any, 
  sortOption: string
): Promise<void> {
  const assetsContainer = document.getElementById("tokenContainerAllAuctions");
  const isMobile = window.innerWidth <= 768;
  

  if (assetsContainer) {
    // Set the key attribute for re-rendering
    assetsContainer.setAttribute('key', refresh.toString());
  }

  const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`) 
      ? `https://${stagingKey}.peaxel.me` 
      : 'https://peaxel.me';

  const defaultAsset = {
    collection: {
      icon_url: "",
      name: "Default Collection",
    },
    created_at: "",
    description: "Default Description",
    id: "",
    image_url: `${linkUrl}/wp-content/uploads/2024/10/ADD-CARD-LINEUP-PEAXEL-SAMPLE.png`,
    metadata: {
      country: "Default Country",
      description: "Default Metadata Description",
      field: "Default Field",
      first_name: "Default First Name",
      image_url: `${linkUrl}/wp-content/uploads/2024/10/ADD-CARD-LINEUP-PEAXEL-SAMPLE.png`,
      name: "Default Name",
      reward_per_token: "",
      token_number: "",
      total_tokens: "",
      total_tokens_first_year: "",
      total_tokens_forth_year: "",
      total_tokens_second_year: "",
      total_tokens_third_year: "",
      vote_per_token: "",
    },
    name: "emptyOne",
    status: "Default Status",
    token_address: "",
    token_id: "",
    updated_at: "",
    uri: null,
    user: "Default User",
    talentCard: {
      token_address: '',
      token_id: '',
      card_id: '',
      name: '',
      owner: '',
      date_update: '',
      image_url: '',
      token_new_url: `${linkUrl}/wp-content/uploads/2024/10/ADD-CARD-LINEUP-PEAXEL-SAMPLE.png`,
      card_experience: 10,
      card_force: '50',
      ETH_value: '',
      EUR_value: '',
      USD_value: '',
      lineup: 1,
      ETH_ratio: '',
      EUR_ratio: '',
      USD_ratio: '',
      buyer_max: '',
      current_order: '',
      desired_dollar: '',
      desired_eth: '',
      desired_euro: '',
      dollar_max: '',
      eth_max: '',
      euro_max: '',
      offers_count: '',
      pending: '',
      toTransfer: '',
      total_lineup: '',
      profile_new_url: `${linkUrl}/wp-content/uploads/2024/10/ADD-CARD-LINEUP-PEAXEL-SAMPLE.png`,
      similar_cards_total: 0,
      similar_cards_on_sale: 0,
      similar_cards_total_pending: 0,
      lineup_per_talent: 0,
      available_cards: 0, //number of available cards to buy for the same talent
      potential_buyers: '',
      total_pending: 0,
      country: '',
state: '',
city: '',
main_nationality: '',
main_activity: '',
main_category: '',
league: '',
league_url: '',
club: '',
club_url: '',
national_team: '',
national_team_url: '',
tag1: '',
tag2: '',
tag3: '',
tag4: '',
tag5: '',
prize1: '',
prize2: '',
prize3: '',
prize4: '',
prize5: '',
talent_age: '',
    talent_name_original: '',
    talent_first_name: '',
    talent_last_name: '',
    talent_instagram: '',
    talent_youTube: '',
    talent_facebook: '',
    talent_tiktok: '',
    talent_X: '',
    talent_pinterest: '',
    talent_linkedIn: '',
    talent_twitch: '',
    talent_website: '',
    talent_force: '',
    talent_description: '',
    instagram_followers: '',
    talent_birthdate: '',
    talent_experience: '',
    json_data: {},
    current_score: '',
    current_game: '',
    card_xp_bonus: '',
    card_force_bonus: '',
    similar_cards_bonus: '',
    final_score: '',
    total_score: '',
    },
  };


  if (locationPage.includes("market")
  || locationPage.includes("team")
  || locationPage.includes("dashboard")
  || locationPage.includes("competition")
  || modal === 'profile') {
    if (!client) {
      console.error("ImmutableXClient is not initialized.");
      return;
    }

    const assetsWithImages = await Promise.all(
      assets.map(async (asset) => {
        if (!asset.talentCard) {
          const talentCardProp = await fetchTalentCardProp(asset.token_address, asset.token_id);
          if (talentCardProp) {
            asset.talentCard = talentCardProp;
          } else {
            console.warn(`Talent card not found for token: ${asset.token_address}, ${asset.token_id}`);
            asset.talentCard = defaultAsset.talentCard; // Set a default empty object or null
          }
        }
        return asset;
      })
    );


///// FILTERING OF ASSETS

const assetsWithTalentCard = await Promise.all(
  assets.map(async (asset) => {
    if (!asset.talentCard) {
      const talentCardProp = await fetchTalentCardProp(asset.token_address, asset.token_id);
      asset.talentCard = talentCardProp || defaultAsset.talentCard;
    }
    return asset;
  })
);

console.log('Assets enriched with talentCard:', assetsWithTalentCard);

// Destructure the filters
const { main_activity, main_category, main_nationality, tags, minPrice, maxPrice, searchQuery } = filterOptions;

// Now apply filters after assets are enriched
const filtreAssets = assetsWithTalentCard.filter(asset => {
  const talentCard = asset.talentCard;

  // **Filter by Nationality**
  const nationalityMatch =
    main_nationality.length === 0 || main_nationality.includes(talentCard?.main_nationality);

  // **Filter by Category**
  const categoryMatch =
    main_category.length === 0 || main_category.includes(talentCard?.main_category);

  // **Filter by Activity**
  const activityMatch =
    main_activity.length === 0 || main_activity.includes(talentCard?.main_activity);

  // **Filter by Tags**
  const assetTags = [talentCard?.tag1, talentCard?.tag2, talentCard?.tag3, talentCard?.tag4, talentCard?.tag5]
    .filter((tag): tag is string => tag !== undefined); // Filter out undefined tags

  const tagsMatch = tags.length === 0 || assetTags.some(tag => tags.includes(tag));

  const deviseNow = localStorage.getItem("pxlCurrency") || "USD";

  const priceMatch =
  parseFloat((talentCard as any)[`${deviseNow}_value`] || "0") >= minPrice &&
  parseFloat((talentCard as any)[`${deviseNow}_value`] || "0") <= maxPrice;
  
  const searchMatch = !searchQuery || searchQuery.length < 3 || 
  (talentCard?.talent_first_name?.toLowerCase().includes(searchQuery.toLowerCase()) || 
  talentCard?.talent_last_name?.toLowerCase().includes(searchQuery.toLowerCase()));

// Only return assets that match all criteria
return nationalityMatch && categoryMatch && activityMatch && tagsMatch && priceMatch && searchMatch;



});




////////////////END OF FILTERING OF ASSETS

////////// SORTING OF ASSETS

const sortedAssets = filtreAssets.sort((a, b) => {
  if (sortOption === 'newest') {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  } else if (sortOption === 'oldest') {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  } else if (sortOption === 'lowestPrice') {
    return parseFloat(a.talentCard?.USD_value || '0') - parseFloat(b.talentCard?.USD_value || '0');
  } else if (sortOption === 'highestPrice') {
    return parseFloat(b.talentCard?.USD_value || '0') - parseFloat(a.talentCard?.USD_value || '0');
  }
  return 0; // default case, no sorting
});

//////


console.log('assets_fi',assets);

    assets = filtreAssets;

    const talentVsCards = ((locationPage.includes("market") && state === 'allTalents') || (locationPage.includes("team") && state === 'allTalents') );
    const teamshortStatus = (todisplay === 'teamshort');
    const cardsportfolioStatus = (todisplay === 'cardsportfolio');
    const lineupStatus = (todisplay === 'lineup');
    const sameCardsStatus = (todisplay === 'samecards');
    const buyoffersStatus = (todisplay === 'buyoffers');
    const selloffersStatus = (todisplay === 'selloffers');
    const addlineupStatus = (todisplay === 'addlineup');

    const dispCurrency = localStorage.getItem("pxlCurrency");
    const currentUser = localStorage.getItem ("walletAddress");
    const currentTalent = localStorage.getItem ("Nprofile");
    const assetsPerRow = isMobile ? 1 : 1;

    if ((currentUser && currentUser.length < 6) || (currentUser === null)) {
    const currentUser='0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a';
    localStorage.setItem('walletAddress', currentUser);
    }

    let filteredAssets: Asset[];

    if (locationPage.includes("team") 
  || sameCardsStatus 
  || state === 'offersReceived'
  || state === 'listedTalents') {
      // Filter assets where asset.name equals currentUser
      assets = assets.filter(asset => asset.user === currentUser);
    }

  
 
    // Existing logic for talentVsCards and general filtering - only 1 asset per different name
    if (talentVsCards) {
      const displayLimit = Infinity;
    
      const uniqueAssets = assets.reduce((acc: Asset[], asset: Asset) => {
        if (!acc.some(a => a.name === asset.name)) {
          acc.push(asset);
        }
        
        return acc;
      }, []);
    
      filteredAssets = uniqueAssets.slice(0, displayLimit);
    } else {
      filteredAssets = assets;
    }



    /// SAMPLING OF LISTED CARDS - AND NUMBER OF PENDING OFFERS AT THE CURRENT USER LEVEL

    let assetsSampling = null;
    let assetsSampling_ListedForSale = null;
    let userNumberCards = 0;
    let numberOfUniqueAssetNames = 0;
    let numberListedForSales = 0;
    let total_pending = 0;
   
     
      assetsSampling = filteredAssets.filter(
      (asset) => ((asset.talentCard?.owner === currentUser))
      );
      if(assetsSampling[0]){
      total_pending = assetsSampling[0].talentCard?.total_pending || 0;
      localStorage.setItem('pendingOffers',total_pending.toString());
      }
      

      if ((state === 'overView') || (state === 'insights')) {
      assetsSampling_ListedForSale = assetsSampling.filter(
        (asset) => (parseInt(asset.talentCard?.current_order) > 10)
      );
      numberListedForSales=assetsSampling_ListedForSale.length;
      const assetNames = assetsSampling.map(asset => asset.name);
      const uniqueAssetNames = new Set(assetNames);
      numberOfUniqueAssetNames = uniqueAssetNames.size;
      userNumberCards = assetsSampling.length;
    }

    //////////////////////////////

    
    let assetsOnSale; 
    //let assetsUser = null; BAM
    let numberCardsUser = 0;
    //let assetsListed=null; BAM
    let numberListedAssets = 0;

    if (modal !== 'profile') {

    if (addlineupStatus) {
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner === currentUser 
          && (parseInt(asset.talentCard?.current_order) < 10) 
          )
      ); 
      
    } else if (locationPage.includes("market"))  {
    
    if (state === 'allAuctions') {
    assetsOnSale = filteredAssets.filter(
      (asset) => (parseInt(asset.talentCard?.current_order) > 10)
    )
    }
    else if (state === 'allTalents') {
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner.length > 8)
      )
    }
    else if (state === 'managersSales') {
      assetsOnSale = filteredAssets.filter(
        (asset) => (parseInt(asset.talentCard?.current_order) > 10 && asset.talentCard?.owner !== '0xbdc8186faa4c50061948edfdfc4a5b534c42a45d')
        )
    }
    else if (state === 'offersReceived') {
      assetsOnSale = filteredAssets.filter(
        (asset) => (parseInt(asset.talentCard?.pending) > 0 && asset.talentCard?.owner === currentUser)
        )
    } else if ((state === 'offersSent') && currentUser) {
      assetsOnSale = filteredAssets.filter((asset) => {
        // Ensure the potential_buyers field exists
        if (asset.talentCard?.potential_buyers) {
          // Convert the potential_buyers string to an array
          const potentialBuyersArray = asset.talentCard?.potential_buyers.split(',');
          // Check if currentUser is in the potentialBuyersArray and current_order is greater than 10
          return potentialBuyersArray.includes(currentUser) && parseInt(asset.talentCard?.current_order) > 10;
        }
        return false; // If potential_buyers field does not exist, exclude the asset
      });
    } else if (state === 'listedTalents') {
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner===currentUser && parseInt(asset.talentCard?.current_order) >10)
        )
    }
    
    } else if (teamshortStatus) {
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner === currentUser)
      )

      const uniqueAssetsMap = new Map();
      assetsOnSale?.forEach((asset) => {
        if (!uniqueAssetsMap.has(asset.talentCard?.name)) {
          uniqueAssetsMap.set(asset.talentCard?.name, asset);
        }
      });
      assetsOnSale = Array.from(uniqueAssetsMap.values());
      if (state === 'overView') {
        if (isMobile) {assetsOnSale = assetsOnSale.slice(0, 4);}
        else {assetsOnSale = assetsOnSale.slice(0, 5);}
      } 
      
    } else if (cardsportfolioStatus) {
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner === currentUser)
      )
      if (state === 'overView') {
          if (isMobile) {assetsOnSale = assetsOnSale.slice(0, 4);}
          else {assetsOnSale = assetsOnSale.slice(0, 5);}
        } 

    } else if (lineupStatus) {
          assetsOnSale = filteredAssets.filter(
            (asset) => (asset.talentCard?.owner === currentUser && asset.talentCard?.lineup == 1)
          );
          const defaultCount = 5 - assetsOnSale.length;
          for (let i = 0; i < defaultCount; i++) {
          assetsOnSale.push(defaultAsset);
          }
          
    } else if (sameCardsStatus) {
          assetsOnSale = filteredAssets.filter(
            (asset) => (asset.talentCard?.owner === currentUser && asset.name === talentName)
          );  

    } 
     } else {
     
      const consideredTalent = talentName.toUpperCase();
  

      let assetsUser;
      let assetsListed; 
      let assetsOwners;
      let numberOwners =0;

      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.name === consideredTalent)
      );

      assetsUser = assetsOnSale.filter(
        (asset) => ((asset.talentCard?.owner === currentUser))
      );

      numberCardsUser = assetsUser.length;

      assetsListed = assetsOnSale.filter(
        (asset) => ((parseInt(asset.talentCard?.current_order) > 10) && asset.talentCard?.owner !== currentUser)
      );

      numberListedAssets = assetsListed.length;
      
      const uniqueOwners = new Set(assetsOnSale.map(asset => asset.talentCard?.owner));

      numberOwners = uniqueOwners.size;
      

      if (assetsOnSale) {
    
        let tableHtml2= '';
        if (numberCardsUser>1){
        tableHtml2 = ` (${numberCardsUser})`;
        } else if (numberCardsUser == 1){
        tableHtml2 = `&nbsp;(1)`;
        }
        else {
        tableHtml2 = `&nbsp;(0)`;
        }
        
        let tableHtml3= '';
        if (numberListedAssets>1){
        tableHtml3 = ` (${numberListedAssets})`;
        } else if (numberListedAssets == 1){
        tableHtml3 = `&nbsp;(1)`;
        }
        else {
        tableHtml3 = `&nbsp;(0)`;
        }

        let tableHtml4= '';
        if (numberOwners>1){
        tableHtml4 = `${numberOwners} ${t('managers')}`;
        } else if (numberOwners == 1){
        tableHtml4 = `1 ${t('manager')}`;
        }
        else {
        tableHtml4 = `0 ${t('managers')}`;
        }

        const targetDiv2 = document.getElementById('seeMyCards');
        if (targetDiv2) {
                targetDiv2.innerHTML = ''; // Clear the inner HTML
                targetDiv2.className = ''; // Clear className if needed
                targetDiv2.innerHTML = tableHtml2; // Set the HTML string
        }
        
        const targetDiv3 = document.getElementById('buyMyCards');
              if (targetDiv3) {
                targetDiv3.innerHTML = ''; // Clear the inner HTML
                targetDiv3.className = ''; // Clear className if needed
                targetDiv3.innerHTML = tableHtml3; // Set the HTML string
              }
        
              const targetDiv2Bottom = document.getElementById('seeMyCardsBottom');
              if (targetDiv2Bottom) {
                      targetDiv2Bottom.innerHTML = ''; // Clear the inner HTML
                      targetDiv2Bottom.className = ''; // Clear className if needed
                      targetDiv2Bottom.innerHTML = tableHtml2; // Set the HTML string
              }
              
              const targetDiv3Bottom = document.getElementById('buyMyCardsBottom');
                    if (targetDiv3Bottom) {
                      targetDiv3Bottom.innerHTML = ''; // Clear the inner HTML
                      targetDiv3Bottom.className = ''; // Clear className if needed
                      targetDiv3Bottom.innerHTML = tableHtml3; // Set the HTML string
                    }

              const targetDiv4 = document.getElementById('managersCount');
              if (targetDiv4) {
                targetDiv4.innerHTML = ''; // Clear the inner HTML
                targetDiv4.className = ''; // Clear className if needed
                targetDiv4.innerHTML = tableHtml4; // Set the HTML string
              }
       
      } 
      
    }

    if (buyoffersStatus && modal === 'profile') {
      assetsOnSale = filteredAssets.filter(
        (asset) => ((asset.name === talentName.toUpperCase()) && (parseInt(asset.talentCard?.current_order) > 10) && asset.talentCard?.owner!== currentUser)
      );
      
    } 
    if (selloffersStatus && modal === 'profile') {
      assetsOnSale = filteredAssets.filter(
      (asset) => (asset.talentCard?.owner === currentUser && asset.name === talentName.toUpperCase())
      ); 

    }


    //////// PRESENTATION OF TITLES & STATISTICS

    /// SAMPLING CARDS - GENERAL MARKET STATS
   
    
    let numberOfUniqueAssetNamesMarket = 0;
    let numberManagerMarket = 0;
    let numberCardsListedForSaleMarket = 0;
    let assetsMarket;

    if ((state === 'insights')) {
 
    const uniqueAssetNamesMarket = new Set(filteredAssets.map(asset => asset.name));
    numberOfUniqueAssetNamesMarket = uniqueAssetNamesMarket.size;

    const uniqueManagerMarket = new Set(filteredAssets.map(asset => asset.talentCard?.owner));
    numberManagerMarket = uniqueManagerMarket.size;
      
    assetsMarket = filteredAssets.filter(
        (asset) => (parseInt(asset.talentCard?.current_order) > 10)
      );

    numberCardsListedForSaleMarket = assetsMarket.length;



 const tableHtml = `
<div style="color: #FAFAFA;
    width: 100% !important;
    font-weight: 400;
    font-size: 120%;
    padding-top: 20px;
    border-top: 1px solid #161616;"> <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-users-rectangle"></i> ${t ('myTalents')}</div>`;
  
 
const tableHtml2 = `
<div style="color: #FAFAFA;
    width: 100% !important;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-top: 1px solid #161616;
    background: #161616;
    font-size: 200%;"> <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-shield-halved"></i> ${t ('market')}</div>`;
  
    const tableHtml5 = `
    <div class="teamStatistics">
      <div class="marketTopStat" >
        <span class="numbTeamTopStat">${numberOfUniqueAssetNamesMarket}</span>
        <span class="labelTeamTopStat">${t('talents')}</span>
      </div>
      <div class="marketTopStat">
        <span class="numbTeamTopStat">${numberManagerMarket}</span>
        <span class="labelTeamTopStat">${t('managers')}</span>
      </div>
      <div class="marketTopStat">
        <span class="numbTeamTopStat">${numberCardsListedForSaleMarket}</span>
        <span class="labelTeamTopStat">${t('listedCards')}</span>
      </div>
    </div>
  `;

  const targetDiv5 = document.getElementById('marketStats');
  if (targetDiv5) {
    targetDiv5.innerHTML = ''; // Clear the inner HTML
    targetDiv5.className = ''; // Clear className if needed
    targetDiv5.innerHTML = tableHtml5; // Set the HTML string
  }

  //const targetDiv = document.getElementById('titleStats');
  //if (targetDiv) {
   
  //  targetDiv.className = ''; // Clear className if needed
  //  targetDiv.innerHTML = tableHtml; // Set the HTML string
  //}
  const targetDiv2 = document.getElementById('titleMarket');
  if (targetDiv2) {
    targetDiv2.innerHTML = ''; // Clear the inner HTML
    targetDiv2.className = ''; // Clear className if needed
    targetDiv2.innerHTML = tableHtml2; // Set the HTML string
  }


   }

  
    if ((state === 'overView') || (state === 'insights')) {
      const tableHtml = `
        <div style="color: #FAFAFA; width: 100% !important; font-weight: 400; font-size: 120%;">
          <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-bolt-lightning"></i> ${t ('Lineup')}
        </div>`;
      const targetDiv = document.getElementById('titleLineup');
      if (targetDiv) {
        targetDiv.innerHTML = ''; // Clear the inner HTML
        targetDiv.className = ''; // Clear className if needed
        targetDiv.innerHTML = tableHtml; // Set the HTML string
      }

      console.log('assetsSampling', assetsSampling);

      if (assetsSampling && assetsSampling[0])  { // Ensure assetsSampling and the second element exist
        const lineupStatusStat = (parseInt(assetsSampling[0].talentCard?.total_lineup) * 20);
    
        let dependance = ''; 
        let dependance2 = '';
        let dependance3 = '';
        
         // Ensure numberListedForSales is defined
          if (numberListedForSales == 0) {
            dependance = 'noShowData';
            dependance2 = 'noShowData';
            dependance3 = 'noShowData';
          } else {
            dependance = 'teamTopStat2';
            dependance2 = 'numbTeamTopStat';
            dependance3 = 'labelTeamTopStat';
          }
        
        const tableHtml8 = `
            <div class="teamTopStat">
              <span class="numbTeamTopStat">${lineupStatusStat}%</span>
              <span class="labelTeamTopStat">${t('lineupCompletion')}</span>
            </div>`;
        const tableHtml9 = `
            <div class="teamTopStat">
              <span class="numbTeamTopStat">${numberOfUniqueAssetNames}</span>
              <span class="labelTeamTopStat">${t('talents')}</span>
            </div>`;
        const tableHtml10 = `
            <div class="teamTopStat">
              <span class="numbTeamTopStat">${userNumberCards}</span>
              <span class="labelTeamTopStat">${t('collectedCards')}</span>
            </div>`;
        const tableHtml11 = `
            <div class="${dependance}">
              <span class="${dependance2}">${numberListedForSales}</span>
              <span class="${dependance3}">${t ('listedCards')}</span>
            </div>`;
        
    
        const targetDiv8 = document.getElementById('teamStats1');
        if (targetDiv8 && (state === "overView" || state === 'insights')) {
          targetDiv8.innerHTML = ''; // Clear the inner HTML
          targetDiv8.className = ''; // Clear className if needed
          targetDiv8.innerHTML = tableHtml8; // Set the HTML string
        } 
        //else if (targetDiv8 && (state !== "overView" || !locationPage.includes("dashboard"))) {
        //  targetDiv8.innerHTML = ''; // Clear the inner HTML
        //}

        const targetDiv9 = document.getElementById('teamStats2');
        if (targetDiv9 && (state === "overView" || state === 'insights')) {
          targetDiv9.innerHTML = ''; // Clear the inner HTML
          targetDiv9.className = ''; // Clear className if needed
          targetDiv9.innerHTML = tableHtml9; // Set the HTML string
        } 
       // else if (targetDiv9 && (state !== "overView" || !locationPage.includes("dashboard"))) {
       //   targetDiv9.innerHTML = ''; // Clear the inner HTML
       // }

        const targetDiv10 = document.getElementById('teamStats3');
        if (targetDiv10 && (state === "overView" || state === 'insights')) {
          targetDiv10.innerHTML = ''; // Clear the inner HTML
          targetDiv10.className = ''; // Clear className if needed
          targetDiv10.innerHTML = tableHtml10; // Set the HTML string
        } 
        //else if (targetDiv10 && (state !== "overView" || !locationPage.includes("dashboard"))) {
        //  targetDiv10.innerHTML = ''; // Clear the inner HTML
        //}

        const targetDiv11 = document.getElementById('teamStats4');
        if (targetDiv11 && (state === "overView" || state === 'insights')) {
          targetDiv11.innerHTML = ''; // Clear the inner HTML
          targetDiv11.className = ''; // Clear className if needed
          targetDiv11.innerHTML = tableHtml11; // Set the HTML string
        } 
        //else if (targetDiv11 && (state !== "overView" || !locationPage.includes("dashboard"))) {
        //  targetDiv11.innerHTML = ''; // Clear the inner HTML
        //}
      }
    } 


    if ((state !== 'overView' && locationPage === 'team')) { 
      
      const tableHtml1 = `
        `;
      const targetDiv1 = document.getElementById('titleLineup');
      if (targetDiv1) {
        targetDiv1.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv1.className = ''; // Clear className if needed
        targetDiv1.innerHTML = tableHtml1; // Set the HTML string
      }

      const tableHtml2 = `
        `;
      const targetDiv2 = document.getElementById('titleTeamShort');
      if (targetDiv2) {
        targetDiv2.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv2.className = ''; // Clear className if needed
        targetDiv2.innerHTML = tableHtml2; // Set the HTML string
      }

      const tableHtml3 = `
        `;
      const targetDiv3 = document.getElementById('titleCardsPortfolio');
      if (targetDiv3) {
        targetDiv3.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv3.className = ''; // Clear className if needed
        targetDiv3.innerHTML = tableHtml3; // Set the HTML string
      }

      const targetDivu = document.getElementById('teamStats');
      if (targetDivu) {targetDivu.innerHTML = '';} // Clear the inner HTML
    } 
    
    if (teamshortStatus && state === 'overView') {
      const tableHtml = `
    <div style="color: #FAFAFA;
    width: 100% !important;
    font-weight: 400;
    font-size: 120%;
    padding-top: 20px;
    border-top: 1px solid #161616";>
          <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-users-rectangle"></i> ${t ('myTalents')}
        </div>`;
      const targetDiv = document.getElementById('titleTeamShort');
      if (targetDiv) {
        targetDiv.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv.className = ''; // Clear className if needed
        targetDiv.innerHTML = tableHtml; // Set the HTML string
      }
    } 

    if (cardsportfolioStatus && state === 'overView') {
      const tableHtml = `
        <div style="color: #FAFAFA; width: 100% !important; font-weight: 400; font-size: 120%; padding-top: 20px; border-top: 1px solid #161616;">
          <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-id-badge"></i> ${t ('myCards')}
        </div>`;
      const targetDiv = document.getElementById('titleCardsPortfolio');
      if (targetDiv) {
       // targetDiv.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv.className = ''; // Clear className if needed
        targetDiv.innerHTML = tableHtml; // Set the HTML string
      }
    } 

 
   //////////////////////////////////////////// END LOCAL TITLES + STATS

    let assetsRowContainer = document.createElement("div");
    assetsRowContainer.classList.add("assets-row-team");

    assetsOnSale?.forEach((asset, index) => {
      if (index % assetsPerRow === 0) {
      }

      let tokenDiv;
      let token1;
      let token2;
     
      if (sameCardsStatus) {
        tokenDiv = document.getElementById(`tokensteamshortname`);
        } else if (buyoffersStatus) {
          tokenDiv = document.getElementById(`tokensteamshortname`);
        } else if (selloffersStatus) {
             tokenDiv = document.getElementById(`tokensteamshortname`);
            } else if (addlineupStatus) {
              tokenDiv = document.getElementById(`tokensteamshortname`);
        } else {
      if (locationPage.includes("market")) {
        tokenDiv = document.getElementById(`tokensAllAuctions`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
      } 
      else if (lineupStatus) {
        tokenDiv = document.getElementById(`tokensLineup`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensteamshort`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokenscardsportfolio`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else if (teamshortStatus) {
        tokenDiv = document.getElementById(`tokensteamshort`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensLineup`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokenscardsportfolio`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else if (cardsportfolioStatus) {
        tokenDiv = document.getElementById(`tokenscardsportfolio`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensLineup`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokensteamshort`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else {
        tokenDiv = document.getElementById(`ghost`);
      }
    }

      if (tokenDiv) {
        tokenDiv.innerHTML = "";
      }
    });
    

      assetsOnSale?.forEach((asset, index) => {
      if (index % assetsPerRow === 0) {
      assetsRowContainer = document.createElement("div");
      assetsRowContainer.classList.add("assets-row-team");
      }

      const tokenId = asset.name;
      let tokenDiv;
      let token1;
      let token2;
      let token3;

      if (sameCardsStatus) {
        tokenDiv = document.getElementById(`tokensteamshortname`);
        } else if (buyoffersStatus) {
            tokenDiv = document.getElementById(`tokensteamshortname`);
        } else if (selloffersStatus) {
                tokenDiv = document.getElementById(`tokensteamshortname`);
              } else if (addlineupStatus) {
                tokenDiv = document.getElementById(`tokensteamshortname`);
        } else {
      if (locationPage.includes("market")) {
        tokenDiv = document.getElementById(`tokensAllAuctions`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
      } 
      else if (lineupStatus) {
        tokenDiv = document.getElementById(`tokensLineup`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensteamshort`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokenscardsportfolio`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else if (teamshortStatus) {
        tokenDiv = document.getElementById(`tokensteamshort`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensLineup`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokenscardsportfolio`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else if (cardsportfolioStatus) {
        tokenDiv = document.getElementById(`tokenscardsportfolio`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensLineup`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokensteamshort`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else {
        tokenDiv = document.getElementById(`ghost`);
      }
    }



      if (tokenDiv) {

  

        const assetDiv = document.createElement("div");
        assetDiv.classList.add("asset");

        const assetStart = document.createElement("div");
        assetStart.classList.add("asset-start");

        const assetInformation = document.createElement("div");
        assetInformation.classList.add("asset-information");

        const assetDetails = document.createElement("div");
        assetDetails.classList.add("asset-details");

      
        
        

      if (!talentVsCards || sameCardsStatus || buyoffersStatus || selloffersStatus) { 
       
      if (!teamshortStatus) {

      if (parseInt(asset.talentCard?.pending) > 0 
      && ((locationPage.includes("market") && state === 'listedTalents')
      || (locationPage.includes("market") && state === 'offersReceived')
      || (locationPage.includes("market") && state === 'allAuctions')
      || (locationPage.includes("market") && state === 'managersSales')
      || ((locationPage.includes("team") || state === 'insights'|| modal === 'profile') 
      //&& todisplay === 'lineup'
      )
      
      )) { 
        
        if (state !== 'allAuctions') {
          if (state !== 'managersSales'){ 
        const label = document.createElement("span");
        label.classList.add("asset-group-label");
        label.innerHTML = `<span class="asset-alert-pink" style="color:#ffffff; font-weight:700;">${parseInt(asset.talentCard?.pending)}&nbsp;<i style="color:#ffffff;" class="fa-solid fa-money-check-dollar"></i></span>`;
        assetDiv.appendChild(label); }
      }}

        const image = document.createElement("img");
        image.classList.add("asset-image");
        image.loading = "lazy";
        image.src = asset.talentCard?.token_new_url || `${linkUrl}/wp-content/uploads/2024/10/ADD-CARD-LINEUP-PEAXEL-SAMPLE.png`;
        if (asset.name !== 'emptyOne') {  
          image.onclick = () => {
            openPageViewModal(asset,'');
          };
        } else {
          image.onclick = () => {
            openSameCardsModal('','addlineup');
          };
        }
        assetStart.appendChild(image);


      }

      }

      if (talentVsCards || teamshortStatus) { 


        if (!talentVsCards) {
        const label = document.createElement("span");
        label.classList.add("asset-group-label");
        
        let spart1 = "";
        let spart2 = "";
        
        if (asset.talentCard?.similar_cards_total_pending !== '0' && asset.talentCard?.owner === currentUser && !locationPage.includes("?activesection=allTalents") ) { 
            spart1 = `<span class="asset-alert-pink" style="color:#ffffff; font-weight:700;">${parseInt(asset.talentCard?.similar_cards_total_pending)}&nbsp;<i style="color:#ffffff;" class="fa-solid fa-money-check-dollar"></i></span>`;
        }
        
        if (asset.talentCard?.lineup_per_talent > 0 && asset.talentCard?.owner === currentUser) { 
            spart2 = `<span class="asset-alert-second" style="color:#ffffff; font-weight:700;">${asset.talentCard?.lineup_per_talent}&nbsp;<i style="color:#ffffff;" class="fa-solid fa-bolt-lightning"></i></span>`;
        }
        
        label.innerHTML = `${spart1}${spart2}`;
        assetDiv.appendChild(label);
      }
         

        
          const image = document.createElement("img");
          image.classList.add("asset-image");
          image.loading = "lazy";
          image.src = asset.talentCard?.profile_new_url;
          image.onclick = () => {
            openPageViewModal(asset,'');
          };
          assetStart.appendChild(image);

        const force = document.createElement("span");
        force.classList.add("force_team");
        force.innerHTML = `<i style="font-size:70%; position:relative; top:-1px;" class="fa-solid fa-shield-halved"></i>&nbsp;${parseFloat(asset.talentCard?.card_force || '50')}`;
        assetStart.appendChild(force);

        const asp = document.createElement("span");
        asp.classList.add("asset-top-middle");

        const averagecardvalue = t('avgCardValue');


        if (dispCurrency === "USD") {
          if (parseFloat(asset.talentCard?.USD_ratio) > 0) {
            asp.innerHTML = `
              <span class="asset-label">
                <span style="font-size:80%; font-weight:200; opacity:0.5;">${averagecardvalue}</span>
                <span>
                  <b>$${parseFloat(asset.talentCard?.USD_value).toFixed(2)}</b>
                  ${!isNaN(parseFloat(asset.talentCard?.USD_ratio)) ? 
                    `<span class="green-text">&nbsp;+${parseFloat(asset.talentCard?.USD_ratio).toFixed(2)}%</span>` : ''}
                </span>
              </span>`;
          } else {
            asp.innerHTML = `
              <span class="asset-label">
                <span style="font-size:80%; font-weight:200; opacity:0.5;">${averagecardvalue}</span>
                <span>
                  <b>$${parseFloat(asset.talentCard?.USD_value).toFixed(2)}</b>
                  ${!isNaN(parseFloat(asset.talentCard?.USD_ratio)) ? 
                    `<span class="red-text">&nbsp;${parseFloat(asset.talentCard?.USD_ratio).toFixed(2)}%</span>` : ''}
                </span>
              </span>`;
          }
        } else if (dispCurrency === "EUR") {
          if (parseFloat(asset.talentCard?.EUR_ratio) > 0) {
            asp.innerHTML = `
              <span class="asset-label">
                <span style="font-size:80%; font-weight:200; opacity:0.5;">${averagecardvalue}</span>
                <span>
                  <b>${parseFloat(asset.talentCard?.EUR_value).toFixed(2)}€</b>
                  ${!isNaN(parseFloat(asset.talentCard?.EUR_ratio)) ? 
                    `<span class="green-text">&nbsp;+${parseFloat(asset.talentCard?.EUR_ratio).toFixed(2)}%</span>` : ''}
                </span>
              </span>`;
          } else {
            asp.innerHTML = `
              <span class="asset-label">
                <span style="font-size:80%; font-weight:200; opacity:0.5;">${averagecardvalue}</span>
                <span>
                  <b>${parseFloat(asset.talentCard?.EUR_value).toFixed(2)}€</b>
                  ${!isNaN(parseFloat(asset.talentCard?.EUR_ratio)) ? 
                    `<span class="red-text">&nbsp;${parseFloat(asset.talentCard?.EUR_ratio).toFixed(2)}%</span>` : ''}
                </span>
              </span>`;
          }
        }
         else {
          asp.innerHTML = `${t('invalidCurrency')}`;
        }

        assetStart.appendChild(asp);

        const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`) 
      ? `https://${stagingKey}.peaxel.me` 
      : 'https://peaxel.me';

        if (!teamshortStatus){

        if (asset.talentCard?.available_cards == 0) { 
        const label1 = document.createElement("span");
        label1.className = "call_action_member_no";

        
       
       // label1.innerHTML = `<span class="call_action_member" style="opacity:0.5; font-weight:300; font-size:80%;"><i style="color:#bfbfbf;" class="fa-solid fa-id-badge"></i>&nbsp; {t('noCardAvailable')}</span>`;
      const noCardAvailableText = t('noCardAvailable');
      label1.innerHTML = `<i style="color:#bfbfbf;" class="fa-solid fa-id-badge"></i>&nbsp; ${noCardAvailableText}`;
        
        label1.onclick = () => {
          window.open(`${linkUrl}/profile/?${asset.name}/`, '');
        };
        assetStart.appendChild(label1); }

        else {

const label2 = document.createElement("span");
label2.className = "call_action_member";

// Create the icon element
const icon = document.createElement("i");
icon.style.color = "#d4055d";
icon.className = "fa-solid fa-id-badge";

// Create the text node for the translated message
const textNode = document.createTextNode(` ${asset.talentCard?.available_cards} ${t('cardsAvailable_plural')}`);

// Append the icon and text node to the main container
label2.appendChild(icon);
label2.appendChild(textNode);

// Add an onclick event to open the profile link in a new tab
label2.onclick = () => {
  window.open(`${linkUrl}/profile/?${asset.name}/`, '_blank');
};

// Append the label to the assetStart element
assetStart.appendChild(label2);
        }

    } else {

      
     // Extract the translation strings before constructing the HTML
const collectedCardsText = t('collectedCards_plural'); // e.g., "Collected Cards" or "Collected Card"
const seeMyCardsText = asset.talentCard?.similar_cards_total > 1 
    ? t('seeMyCards')  // e.g., "See My Cards"
    : t('seeMyCard');  // e.g., "See My Card"

// Create the label2 element
const label2 = document.createElement("span");

label2.innerHTML = `
<span>
<span class="call_action_member_yes">
        <i style="color:#bfbfbf;" class="fa-solid fa-id-badge"></i>&nbsp;&nbsp;
        ${asset.talentCard?.similar_cards_total} ${collectedCardsText}
    </span>
    </span>`;
label2.onclick = () => {
    window.open(`${linkUrl}/profile/?${asset.name}/`, '_blank');
};
assetStart.appendChild(label2);

// Create the sellButton element
const sellButton = document.createElement("span");
sellButton.innerHTML = seeMyCardsText;
sellButton.className = "button_market_asset_manage";
sellButton.onclick = () => {
    openSameCardsModal(asset.name, '');
};
assetStart.appendChild(sellButton);


    }

       
      }



        assetDiv.appendChild(assetStart);

        assetDiv.appendChild(assetInformation);

        assetInformation.appendChild(assetDetails);


        let statusString = "";

        
      
      if (!talentVsCards || sameCardsStatus || buyoffersStatus || selloffersStatus) { 
        if (!teamshortStatus && asset.name !== 'emptyOne' ) { 
        const experience = document.createElement("span");
        experience.style.cssText = "width:100%; margin-top:-5px"; // Add your styles here
                if (!experience.hasChildNodes()) {
                  ReactDOM.render(
                    <Suspense fallback={<div>Loading Component A...</div>}>
                    <ExperienceDisplayLazy
              card={asset.talentCard}
              locationXP={locationPage}
              activeSection={state}
      /></Suspense>,
                    experience
                  );
                }
                
        
         assetDetails.appendChild(experience);

         if (state !== 'insights') { 
          if (todisplay !== 'lineup') { 

       // if (matchingOrder) {
          // Array to store promises
          

          let classNameX = "";

          if (modal === 'profile') {
            classNameX = "general_price_profile";
          } else {
            classNameX = "general_price";
          }

        
            
          if (asset.talentCard && parseInt(asset.talentCard?.offers_count) > 0) {
            
                const name = document.createElement("div");
                name.classList.add("asset-name");

                if (dispCurrency === "USD") {
                  if (parseInt(asset.talentCard?.offers_count) > 1) {
                    name.innerHTML = `<span class="${classNameX}"><span class="order-price">$${parseFloat(asset.talentCard?.dollar_max).toFixed(2)}</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.eth_max).toFixed(6)} ETH</span></span>`;
                  } else {
                    name.innerHTML = `<span class="${classNameX}"><span class="order-price">$${parseFloat(asset.talentCard?.dollar_max).toFixed(2)}</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.eth_max).toFixed(6)} ETH</span></span>`;
                  }
                } else if (dispCurrency === "EUR") {
                  if (parseInt(asset.talentCard?.offers_count) > 1) {
                    name.innerHTML = `<span class="${classNameX}"><span class="order-price">${parseFloat(asset.talentCard?.euro_max).toFixed(2)}€</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.eth_max).toFixed(6)} ETH</span></span>`;
                  } else {
                    name.innerHTML = `<span class="${classNameX}"><span class="order-price">${parseFloat(asset.talentCard?.euro_max).toFixed(2)}€</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.eth_max).toFixed(6)} ETH</span></span>`;
                  }
                } else {
                  name.innerHTML = `${t('invalidCurrency')}`;
                }

                name.onclick = () => {
                  if (parseInt(asset.talentCard?.current_order)>0) {
                    openOrderModal(asset.talentCard?.current_order, asset.talentCard, locationPage, state);
                  } else {
                    openAssetModal(asset, "", locationPage);
                  }
                };

                assetDetails.appendChild(name);
          }

      

          if (asset.talentCard && parseInt(asset.talentCard?.offers_count) === 0 && parseInt(asset.talentCard?.current_order) >10  ) {
                const name = document.createElement("div");
                name.classList.add("asset-name");

                if (dispCurrency === "USD") {
                  name.innerHTML = `<span class="${classNameX}"><span class="order-price">$${(
                    parseFloat(asset.talentCard?.desired_dollar) * 1.05).toFixed(2)}</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.desired_eth).toFixed(6)} ETH</span></span>`;
                } else if (dispCurrency === "EUR") {
                  name.innerHTML = `<span class="${classNameX}"><span class="order-price">${parseFloat(asset.talentCard?.desired_euro).toFixed(2)}€</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.desired_eth).toFixed(6)} ETH</span></span>`;
                } else {
                  name.innerHTML = `${t('invalidCurrency')}`;
                }

                name.onclick = () => {
                  if (parseInt(asset.talentCard?.current_order)>0) {
                    openOrderModal(asset.talentCard?.current_order, asset.talentCard, locationPage, state);
                  } else {
                    openAssetModal(asset, "", locationPage);
                  }
                };

                assetDetails.appendChild(name);
              }  

             /* if (asset.talentCard && parseInt(asset.talentCard?.offers_count) === 0 && parseInt(asset.talentCard?.current_order) <10  ) {
                const name = document.createElement("div");
                name.classList.add("asset-name");

                
                name.innerHTML = `<span>&nbsp;</span>`;
          

                assetDetails.appendChild(name);
              }  
*/

       /* const info2 = document.createElement("span");
        info2.classList.add("asset-infoo");

        if (asset.talentCard && parseInt(asset.talentCard?.offers_count) > 0) {
        info2.innerHTML = `<span class="order-ETH">${parseInt(asset.talentCard?.offers_count)} ${parseInt(asset.talentCard?.offers_count) > 1 ? 'Offers' : 'Offer'}</span>`;
        } else {
        info2.innerHTML = `<span class="order-ETH">&nbsp;</span>`;
        }
        
        info2.onclick = () => {
          if (parseInt(asset.talentCard?.current_order)>0) {
            openOrderModal(asset.talentCard?.current_order, asset.talentCard, locationPage, state);
          } else {
            openAssetModal(asset, "", locationPage);
          }
        };

        assetDetails.appendChild(info2); */

        if (asset.talentCard && parseInt(asset.talentCard.offers_count) > 0) {
          const info2 = document.createElement("span");
          info2.classList.add("asset-infoo");
          
          const offerCount = parseInt(asset.talentCard.offers_count);
          info2.innerHTML = `<span class="order-ETH">${offerCount} ${offerCount > 1 ? t('offers') : t('offer')}</span>`;
          
          info2.onclick = () => {
            if (parseInt(asset.talentCard?.current_order) > 0) {
              openOrderModal(asset.talentCard.current_order, asset.talentCard, locationPage, state);
            } else {
              openAssetModal(asset, "", locationPage);
            }
          };
        
          assetDetails.appendChild(info2);
        }

      
              if (asset.talentCard) {
              //  const { pending, inProgress, toTransfer } = orderInfo;

                if (parseInt(asset.talentCard?.toTransfer) > 0) {
                  statusString = `<span>${t('sold')}</span>`;
                } else {
                if (asset.talentCard?.owner === currentUser) {
                  if (parseInt(asset.talentCard?.current_order)>0) {
                  statusString = `<span>${t('manageListedCard')}</span>`;
                  } else 
                  {
                  if (asset.talentCard?.lineup == 0) {
                  statusString = `<span>${t('listForSale')}</span>`;
                  } else
                  { statusString = `<span>${t('Lineup')}</span>`; }
                  }
                } else {
                  statusString = `<span>${t('makeAnOffer')}</span>`;
                }
                }
              }

              const sellButton = document.createElement("span");
              sellButton.innerHTML = statusString;
              if (asset.talentCard?.lineup == 0) { 
              sellButton.className = "button_market_asset_manage"; }
              else {
                sellButton.className = "button_market_asset_sell_blocked";
              }

              sellButton.onclick = () => {
                if (parseInt(asset.talentCard?.current_order)>0) {
                  openOrderModal(asset.talentCard?.current_order, asset.talentCard, locationPage, state);
                } else {
                  openAssetModal(asset, "", locationPage);
                }
              };

              assetInformation.appendChild(sellButton);
            } // end of NON lineup condition
            } // end of NON dashboard condition

      }} /// end of !talentVsCards
    

        assetsRowContainer.appendChild(assetDiv);
        tokenDiv.appendChild(assetsRowContainer);
      } else {
        console.error(`Div not found in the DOM`);
      }
    });
  }
}


const App = () => {

const { t } = useTranslation();
const isMobileConst = window.innerWidth <= 768;
// initialise Immutable X Link SDK
//const linkIframeOptions: ConfigurableIframeOptions = {
 // className: 'my-link-iframe',
//};


const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };

const [newsNumber, setNewsNumber] = useState(0);

const handleNewsCount = (hasNews:any) => {
  setNewsNumber(hasNews); // Update the state based on whether news exists
};


let teamName = '';
const myteamname = localStorage.getItem('pxlTeamname');

if (myteamname === null || myteamname?.length < 3) {
  teamName = t('myTeam');
} else {
  teamName = myteamname;
}

let managerName = '';
const myManagerName = localStorage.getItem('pxlManager');

if (myManagerName === null || myManagerName?.length < 3) {
  managerName = '';
} else {
  managerName = myManagerName;
}

const [isFilterModalOpen, setIsFilterModalOpen] = useState(!isMobileConst);
const [filterOptions, setFilterOptions] = useState({
  minPrice: 0,
  maxPrice: 1000,
  main_activity: [],
  main_category: [],
  main_nationality: [],
  tags: []
});

const toggleFilterModal = () => {
  setIsFilterModalOpen(!isFilterModalOpen);
};

const applyFilters = (newFilterOptions: any) => {
  // Update the filter options in state
  setFilterOptions(newFilterOptions);

};


//const link = new Link(process.env.REACT_APP_SANDBOX_LINK_URL, linkIframeOptions);
const link = useMemo(() => new Link(process.env.REACT_APP_SANDBOX_LINK_URL, linkIframeOptions), [linkIframeOptions]);

const publicApiUrl = useMemo(() => process.env.REACT_APP_SANDBOX_ENV_URL ?? '', []);

const [wallet, setWallet] = useState<string | undefined>(undefined);
const [balance, setBalance] = useState<ImmutableMethodResults.ImmutableGetBalanceResult | null>(null);
const [client, setClient] = useState<any>(null); // Initialize your client here
const [ethBalance, setEthBalance] = useState<number | null>(null);
const [assets, setAssets] = useState<Asset[]>([]);
const [walletAddress, setWalletAddress] = useState<string | null>(null); // Initialize wallet address here
const [isWalletMenuOpen, setIsWalletMenuOpen] = useState(false);
const [hasWallet, setHasWallet] = useState(false);
const [shouldRenderHeader, setShouldRenderHeader] = useState(false);
const [shouldInitializeWallet, setShouldInitializeWallet] = useState(false);
const [refresh, setRefresh] = useState(0);
const [selectedAsset, setSelectedAsset] = useState<SelectedAsset | null>(null);
const [selectedTalent, setSelectedTalent] = useState<{ assetName: string, toDisplay: string } | null>(null);
const [exchangeRates, setExchangeRates] = useState<{ USD: number; EUR: number } | null>(null);
const [selectedOrder, setSelectedOrder] = useState<{
  order: Order | null;
  exchangeRates: { USD: number; EUR: number } | null;
  currency: 'EUR' | 'USD';
}>({
  order: null,
  exchangeRates: null,
  currency: 'USD', // Set the default currency as needed
});
const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
const [isSameCardsModalOpen, setIsSameCardsModalOpen] = useState(false);
const [ethBalanceInDollars, setEthBalanceInDollars] = useState<number | null>(null);
const selectedCurrency = localStorage.getItem('pxlCurrency');
const [isPendingOffers, setIsPendingOffers] = useState(0);

const [latestValue, setLatestValue] = useState<number | null>(null);
const [latestBalance, setLatestBalance] = useState<number | null>(null);

const [isPageViewModalVisible, setIsPageViewModalVisible] = useState(false);
const [assetProfileView, setAssetProfileView] = useState<Asset | null>(null);
const [whatPage, setWhatPage] = useState('dashboard');
const [activeSection, setActiveSection] = useState('insights');
const [whatModal, setWhatModal] = useState('');
const [talentProfile, setTalentProfile] = useState<{ assetTalent: string, toDisplayTalent: string } | null>(null);

const [currentURLState, setCurrentURLState] = useState(0);
const [isHowToPlayVisible, setIsHowToPlayVisible] = useState(false); // State to control visibility
const [onGame, setOnGame] = useState(false);
const [onTalentAdv, setOnTalentAdv] = useState(false);

const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false); // State to manage the modal

const [isTalentSignupModalOpen, setIsTalentSignupModalOpen] = useState(false);

const [isScrolled, setIsScrolled] = useState(false);

const [sortOption, setSortOption] = useState('newest'); // Default to 'newest'

const [totalScore, setTotalScore] = useState<string | null>(null);
const [totalLineup, setTotalLineup] = useState<string | null>(null);
const [autoKeep, setAutoKeep] = useState('0');

const [selectedGameweek, setSelectedGameweek] = useState<string>('');
const [userPosition, setUserPosition] = useState<number | null>(null); // State for user position
const [distinctOwnersCount, setDistinctOwnersCount] = useState<number | null>(null); // State for distinct owners count


const currentHostname = window.location.href;
const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
const linkUrl = currentHostname.includes(`${stagingKey}`) 
  ? `https://${stagingKey}.peaxel.me` 
  : 'https://peaxel.me';

  const today = new Date().getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
  const isUnlocked = today >= 1 && today <= 4 ? "unlocked" : "locked"; 


  const toggleHowToPlay = () => {
    setIsHowToPlayVisible((prev) => !prev); // Toggle the visibility
  };

const handleTalentSignupOpen = () => {
  setIsTalentSignupModalOpen(true);
};

const handleTalentSignupClose = () => {
  setIsTalentSignupModalOpen(false);
};

const handleGameweekChange = (gameweek: string) => {
  setSelectedGameweek(gameweek);
};

const handleUserPositionChange = (position: number) => {
  setUserPosition(position);
};

const handleDistinctOwnersCountChange = (count: number) => {
  setDistinctOwnersCount(count);
};

const handleCloseWelcomeModal = () => {
  setIsWelcomeModalOpen(false);
};

const handleLinkClick = (page:any, section:any) => {
  const newUrl = `${linkUrl}/game/?page=${encodeURIComponent(page)}&section=${encodeURIComponent(section)}`;
  window.history.pushState({}, '', newUrl); // Update the URL
  localStorage.setItem('pxlPage',page);
  localStorage.setItem('pxlSection',section);
  setCurrentURLState(currentURLState +1);
};




const closePageViewModal = useCallback(() => {
  // setRefresh(prev => prev + 1);
   setWhatModal('');
   setAssetProfileView(null);
   setTalentProfile(null)
   setIsPageViewModalVisible(false);
   
   
 
 }, []);
 
 const closeAssetModal = useCallback(() => {
   //setRefresh(prev => prev + 1);
   setSelectedAsset(null);
   setIsAssetModalOpen(false);
   
 }, []);
 
 const closeSameCardsModal = useCallback(() => {
   //setRefresh(prev => prev + 1);
   setSelectedTalent(null);
   setIsSameCardsModalOpen(false);
   
 }, []);
 
const closeOrderModal = useCallback(() => {
   setSelectedOrder({
     order: null,
     exchangeRates: null,
     currency: 'USD',
   });
  // setRefresh(prev => prev + 1);
   setIsOrderModalOpen(false);
   
   
 }, []);
 
const handleToggleWalletMenu = useCallback(() => {
   setIsWalletMenuOpen(prev => !prev);
 }, []);
 
const handleProfileButtonClick = useCallback((talentName: string, toDisplay: string) => {
 openSameCardsModal(talentName,toDisplay);
 }, [client]);
 
const handleRefresh = useCallback(() => setRefresh((prev) => prev + 1), []);
 
const buildIMX = useCallback(async () => {
   const savedWalletAddress = localStorage.getItem('walletAddress');
   const clientInstance = await ImmutableXClient.build({ publicApiUrl });
   setClient((prevClient:any) => (prevClient !== clientInstance ? clientInstance : prevClient));
   if (savedWalletAddress) {
     setWallet((prevWallet) => (prevWallet !== savedWalletAddress ? savedWalletAddress : prevWallet));
     await fetchAssets(clientInstance, savedWalletAddress, setAssets, refresh, '', activeSection,'', whatPage, whatModal, filterOptions, sortOption);
     const balance = await clientInstance.getBalance({ user: savedWalletAddress, tokenAddress: 'eth' });
     setBalance((prevBalance) => (prevBalance !== balance ? balance : prevBalance));
      }
 }, [publicApiUrl]);

 const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentWeek = String(getWeekNumber(currentDate)).padStart(2, '0');
const mainGameweek = `${currentYear}-${currentWeek}`;

// Helper function to get week number
function getWeekNumber(date: Date): number {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

const fetchAssets = useCallback(
  _.throttle( 
  async (
  client: ImmutableXClient | null, // The ImmutableX client instance or null (provided by your application)
  savedWalletAddress: string | null, // The wallet address as a string or null (retrieved from local storage)
  setAssets: React.Dispatch<React.SetStateAction<Asset[]>>, // A state updater function for assets
  refresh: number, // A callback to display assets on the page
  talentName: string, 
  state: string,
  toDisplay: string,
  locationPage: string,
  modal: string,
  filterOptions: {
    minPrice: number;
    maxPrice: number;
    main_activity: string[]; // Change to camelCase
    main_category: string[]; // Change to camelCase
    main_nationality: string[]; // Change to camelCase
    tags: string[];
  },
  sortOption: string
) => {

  console.log('filtfetch',filterOptions);
  // case of visitors

  if ( (savedWalletAddress && savedWalletAddress.length < 6)  || savedWalletAddress === null) {
    savedWalletAddress = '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a';
  }

  try {

    let assetCursor;
    let fetchedAssets: Asset[] = [];
    talentName = talentName.toUpperCase();

      do {

       
        // Continue fetching assets using pagination (cursor)
        //if (savedWalletAddress ) {
        const assetRequest: any = await client?.getAssets({
          cursor: assetCursor,
          collection: '0x14464556d7979ab59426158f7f9181c8359c7bfc',
        });
        // Concatenate the newly fetched assets to the existing list
        fetchedAssets = fetchedAssets.concat(assetRequest.result);
        assetCursor = assetRequest.cursor;
     // }

      } while (assetCursor);

      // Update the assets state with the fetched assets
      setAssets(fetchedAssets);

      const promises = [];

      
    if (modal === 'profile') {

    if (toDisplay === '') {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh, talentName, state, '', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption)); 
      }
      
    else if (toDisplay === 'buyoffers') {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh, talentName, state, 'buyoffers', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
    }
    else if (toDisplay === 'selloffers') {
      promises.push(displayAllAuctions(fetchedAssets, client, refresh, talentName, state, 'selloffers', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption)); 
    }
    
  } else {

      if ((locationPage.includes('team') || locationPage.includes('competition'))) {

      if (talentName.length > 5) {
  
      if ((state === 'allMyTalents' || state === 'overView')) {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, talentName, state, 'samecards', locationPage, '',t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
      }}
      
      else {
        
        if (state === 'overView' && toDisplay !== 'addlineup') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'lineup', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'teamshort', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'cardsportfolio', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
        } 
        else if ((state === 'myLineup' || state === 'overView' || state === 'insights') && toDisplay === 'addlineup') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'addlineup', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
        }
        else if (state === 'myLineup' && toDisplay !== 'addlineup') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'lineup', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
        }
        else if (state === 'allMyTalents') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'teamshort', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));

        }
        else if (state === 'allMyCards') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'cardsportfolio', locationPage, modal,t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
        }
    } 

      }
    
     else if (state === 'insights' ) {
      
      if(toDisplay !== 'addlineup') {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'lineup', locationPage, '',t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
      } 
      else {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'addlineup', locationPage, '',t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
      }

    }
    
     else if (locationPage.includes('market')) {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh,'', state, '', locationPage, '',t as unknown as (key: string, options?: any) => string, filterOptions, sortOption));
      }

    }

      await Promise.all(promises);
    //}
  } catch (error) {
    // Handle any errors that occur during asset fetching
    console.error('Error fetching assets:', error);
  }
},
1000 // Throttle API calls to 1 per second
  ),
[client, setAssets, refresh]
);

const isFilterEmpty = () => {
  const { main_activity, main_category, main_nationality, tags, minPrice, maxPrice } = filterOptions;
  const areCheckboxesEmpty = main_activity.length === 0 && main_category.length === 0 &&
    main_nationality.length === 0 && tags.length === 0;
  const isPriceRangeDefault = minPrice === 0 && maxPrice === 1000;  // Assuming these are your default values

  console.log('areCheckboxesEmpty',areCheckboxesEmpty);
  console.log('isPriceRangeDefault',isPriceRangeDefault);

  return areCheckboxesEmpty && isPriceRangeDefault;
};
// Function to clear all filters
const clearAllFilters = () => {
  setFilterOptions({
    main_activity: [],
    main_category: [],
    main_nationality: [],
    tags: [],
    minPrice: 0,
    maxPrice: 1000
  });
};
// Function to handle filter changes
const handleApplyFilters = useCallback(_.debounce((newFilters: any) => {
  setFilterOptions(newFilters);
}, 500), []);

const NumberFormatter = ({ number }: { number: string }) => {
  // Convert string to number and format with spaces instead of commas
  const formattedNumber = Number(number).toLocaleString().replace(/,/g, ' ');

  return <span>{formattedNumber}</span>;
};

const handleSortChange = (sortOption: string) => {
  setSortOption(sortOption);
  handleRefresh(); // Trigger refresh after sort
};

const openTalentSignupModal = useCallback(() => {
  handleTalentSignupOpen();
}, []);

const handleMarketLinkClick = useCallback(() => {
  handleLinkClick('market', 'allTalents');
}, [handleLinkClick]);

const [opacity, setOpacity] = useState(1); // Track the opacity of the menu

let lastScrollTop = 0;


useEffect(() => {
  setIsFilterModalOpen(!isMobileConst);
}, [isMobileConst]);

useLayoutEffect(() => {

    const gameIn = window.location.pathname.includes('/game');
    setOnGame(gameIn);

    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const isStagingPath = window.location.pathname.includes(`${stagingKey}`);
    
    const talentAdvIn = window.location.pathname.includes('/advantages-for-talents-on-peaxel');
    setOnTalentAdv(talentAdvIn);

    
  }, []);

  

useEffect(() => {

    const storedCurrency = localStorage.getItem('pxlCurrency') || 'USD'; 
    localStorage.setItem('pxlCurrency',storedCurrency);

    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; 
    localStorage.setItem('pxlLanguage',storedLanguage);


    if (onGame) {
      const savedWalletAddress = localStorage.getItem('walletAddress');
      handleLinkClick('dashboard', 'insights');

      if (savedWalletAddress === null || savedWalletAddress.length <= 8) {
        setWallet('0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a');
        localStorage.setItem('walletAddress', '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a');
        
        setIsWelcomeModalOpen(true); 
      }

      const pageIni = localStorage.getItem('pxlPage');
      const sectionIni = localStorage.getItem('pxlSection');
      if (pageIni !== '') {
        if (sectionIni !== '') {
          handleLinkClick(pageIni, sectionIni);
        } else {
          handleLinkClick('dashboard', 'insights');
        }
      }
    } else {
      localStorage.setItem('pxlPage', '');
      localStorage.setItem('pxlSection', '');
    }

    if (onGame===true) {
      const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const isStagingPath = window.location.pathname.includes(`${stagingKey}`);

      const savedWalletAddress = localStorage.getItem('walletAddress');
      handleLinkClick('dashboard', 'insights');

      if ((savedWalletAddress !== null && savedWalletAddress.length > 8) || isStagingPath) {
        setIsWelcomeModalOpen(false); 
      } else {
        setIsWelcomeModalOpen(true); 
      }
    }
  }, [onGame]);

useEffect(() => {
  const handlePopState = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');
    const section = urlParams.get('section');

    if (page !== null) {
      setWhatPage(decodeURIComponent(page));
      setActiveSection(section ? decodeURIComponent(section) : '');
    } else {
      setWhatPage('dashboard');
      setActiveSection('insights');
    }
  };

  handleRefresh();

  window.addEventListener('popstate', handlePopState);
  // Call handlePopState to set the initial state based on the URL
  handlePopState();

  

  return () => {
    window.removeEventListener('popstate', handlePopState);
  };

}, [currentURLState]);

useEffect(() => {
  async function fetchLatestValue() {
    try {
      const walletAddress = localStorage.getItem('walletAddress');
      const currency = localStorage.getItem('pxlCurrency') || 'USD';
      const response = await axios.get(`${linkUrl}/wp-json/custom/v1/get-latest-portfolio-value/`, {
        params: {
          wallet_address: walletAddress,
          currency: currency,
        },
      });
      setLatestValue(response.data.value); // Assuming response contains the value
    } catch (error) {
      console.error('Error fetching latest portfolio value:', error);
    }
  }

  fetchLatestValue();
}, []);

useEffect(() => {
  async function fetchLatestBalance() {
    try {
      const walletAddress = localStorage.getItem('walletAddress');
      const currency = localStorage.getItem('pxlCurrency') || 'USD';
      const response = await axios.get(`${linkUrl}/wp-json/custom/v1/get-latest-roi/`, {
        params: {
          wallet_address: walletAddress,
          currency: currency,
        },
      });
      setLatestBalance(response.data.roi_value); // Assuming response contains the value
    } catch (error) {
      console.error('Error fetching latest portfolio value:', error);
    }
  }

  fetchLatestBalance();
}, []);

useEffect(() => {
  const handlePopState = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');
    const section = urlParams.get('section');

    if (page !== null) {
      setWhatPage(decodeURIComponent(page));
      setActiveSection(section ? decodeURIComponent(section) : '');
    } else {
      setWhatPage('dashboard');
      setActiveSection('insights');
    }
  };

  handleRefresh();

  window.addEventListener('popstate', handlePopState);
  // Call handlePopState to set the initial state based on the URL
  handlePopState();

  return () => {
    window.removeEventListener('popstate', handlePopState);
  };

}, [currentURLState]);

useEffect(() => {
  const handleURLChange = () => {
    setCurrentURLState((prevState) => prevState + 1);
  };

  window.addEventListener('popstate', handleURLChange);

  return () => {
    window.removeEventListener('popstate', handleURLChange);
  };
}, []);

useEffect(() => {
  // Function to check and update pending offers
  const checkAndUpdatePendingOffers = () => {
    const pendingOffers = parseInt(localStorage.getItem('pendingOffers') ?? '0', 10);

    // Only update if the pending offers count has changed
    if (pendingOffers !== isPendingOffers) {
      // Update the state with the new pending offers count
      setIsPendingOffers(pendingOffers);
    }

    // Function to update the DOM with pending offers alert
    const updateDOMWithPendingOffers = () => {
      const targetDiv = document.getElementById('displayPendingOffers');

      if (targetDiv) {
        if (pendingOffers > 0) {
          // If there are pending offers, display the icon and count
          targetDiv.innerHTML = `
            <span class="button-search">
              <i class="fa-solid fa-money-check-dollar"></i>
            </span>
            <span class="numberPendingOffers">${pendingOffers}</span>
          `;
          targetDiv.style.display = 'block'; // Make sure it's visible
        } else {
          // If no pending offers, hide the alert
          targetDiv.style.display = 'none';
        }
      } else {
        // Retry the DOM update on the next animation frame if the targetDiv isn't ready
        requestAnimationFrame(updateDOMWithPendingOffers);
      }
    };

    // Start the update process after ensuring DOM is ready
    requestAnimationFrame(updateDOMWithPendingOffers);
  };

  // Run the check and update function once on mount and whenever dependencies change
  checkAndUpdatePendingOffers();
}, [isPendingOffers, activeSection, whatPage]);

useEffect(() => {
  async function fetchExchangeRates() {
    try {
      // Fetch USD rate
      const responseUsd = await axios.get(
        `${linkUrl}/wp-json/custom/v1/get_metadata_by_uppername?uppername=HELLO@PEAXEL.ME&metadata_label=rate_usd`
      );
      const usdRate = responseUsd.data/100000000000;
      localStorage.setItem('usdRate', usdRate.toString());

      // Fetch EUR rate
      const responseEur = await axios.get(
        `${linkUrl}/wp-json/custom/v1/get_metadata_by_uppername?uppername=HELLO@PEAXEL.ME&metadata_label=rate_eur`
      );
      const eurRate = responseEur.data/100000000000;
      localStorage.setItem('eurRate', eurRate.toString());

      // Update the state with both rates
      setExchangeRates({ USD: usdRate, EUR: eurRate });
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
    }
  }

  fetchExchangeRates();
}, []);

useEffect(() => {
  const openAssetModal = (asset: Asset, order_id: string, locationPath: string) => {
    // Close the order modal if it's open
    setIsOrderModalOpen(false);

    setSelectedAsset({ asset, order_id, locationPath });
    if (whatPage.includes('team') || whatPage.includes('dashboard') || whatModal === 'profile' || whatPage.includes('market') || whatPage.includes('competition')) {
      setIsAssetModalOpen(true); // Open the modal
    }
  };

  setOpenAssetModalFunc(openAssetModal);

}, [exchangeRates]);

useEffect(() => {
  const openSameCardsModal = (assetName: string, toDisplay: string) => {
    // Close the order modal if it's open
   setSelectedTalent({ assetName, toDisplay });
  
  setIsSameCardsModalOpen(false);
 
   
   if (whatPage.includes('team') || whatModal === 'profile' || whatPage.includes('market') || whatPage.includes('dashboard') || whatPage.includes('competition')) {
    setIsSameCardsModalOpen(true); // Open the modal
  }
   
  };

  setOpenSameCardsModalFunc(openSameCardsModal);

}, [exchangeRates]);

useEffect(() => {
  const openPageViewModal = async (asset: Asset, toDisplayTalent: string) => {
    // Close the order modal if it's open
    setTalentProfile({assetTalent:asset.name, toDisplayTalent:toDisplayTalent});
    setWhatModal('profile');
    setAssetProfileView(asset);
    setIsPageViewModalVisible(false);
    if (whatPage.includes('team') || whatPage.includes('market') || whatPage.includes('dashboard') || whatPage.includes('competition')) {
    setIsPageViewModalVisible(true);
  }
   
  };

  setPageViewModalFunc(openPageViewModal);

}, [exchangeRates]);

useEffect(() => {
  const openOrderModal = async (order_id: string, cardProp: TalentCard, locationPath: string, activeSection: string) => {
    // Close the asset modal if it's open
    setIsAssetModalOpen(false);
  
    let orderManage: Order | null = null;
  
    try {
      const orderEndpoint = `https://api.sandbox.x.immutable.com/v3/orders/${order_id}`;
  
      // Make the API call to get order details
      const orderResponse = await axios.get(orderEndpoint);
  
      orderManage = orderResponse.data;

      if (orderManage) {
        // Find the corresponding asset for the order and enrich the order with asset.talentCard
        const matchingAsset = assets.find(asset => 
        asset.token_address === orderManage!.sell.data.token_address && 
        asset.token_id === orderManage!.sell.data.token_id
        );
  
        if (matchingAsset) {
          orderManage.talentCard = cardProp;
        }
      }
  
    } catch (error) {
      console.error('Error fetching currency conversion:', error);
    }
  
    const oCurrency = localStorage.getItem('pxlCurrency') || 'USD';
  
    setSelectedOrder({
      order: orderManage,
      exchangeRates: exchangeRates,
      currency: oCurrency as 'EUR' | 'USD',
    });
  
    if (whatPage.includes('team') || whatPage.includes('dashboard') || whatModal === 'profile' || whatPage.includes('market') || whatPage.includes('competition')) {
      setIsOrderModalOpen(true); // Open the modal
    }
  };
  
  setOpenOrderModalFunc(openOrderModal);
  

}, [exchangeRates]);

useEffect(() => {

  buildIMX();
  setShouldRenderHeader(true);
  setShouldInitializeWallet(true);
  /*balanceChart();*/
  /*transaction_action();*/

}, [buildIMX]);

useEffect(() => {
  if (shouldInitializeWallet) {
    const savedWalletAddress = localStorage.getItem('walletAddress');
    if (savedWalletAddress) {
      setWallet(savedWalletAddress);
      setHasWallet(savedWalletAddress.length > 8);
    }
  }
}, [shouldInitializeWallet]);

useEffect(() => {
  const savedWalletAddress = localStorage.getItem('walletAddress');

if (whatModal === 'profile' && talentProfile !== null){
  if (client && savedWalletAddress && isSameCardsModalOpen && selectedTalent !==null) {   
  fetchAssets(client, savedWalletAddress, setAssets, refresh, selectedTalent.assetName, '',selectedTalent.toDisplay, '', whatModal, filterOptions, sortOption);  
}

if (client && savedWalletAddress && !isSameCardsModalOpen ) {   
  fetchAssets(client, savedWalletAddress, setAssets, refresh, talentProfile.assetTalent, '','', '', whatModal, filterOptions, sortOption);  

}

} else {
  if (client && savedWalletAddress && !isSameCardsModalOpen && selectedTalent ===null) {   
  fetchAssets(client, savedWalletAddress, setAssets, refresh,'', activeSection,'', whatPage, '', filterOptions, sortOption);  
  }
  if (client && savedWalletAddress && isSameCardsModalOpen && selectedTalent !==null) {   
  fetchAssets(client, savedWalletAddress, setAssets, refresh, selectedTalent.assetName, activeSection,selectedTalent.toDisplay, whatPage, '', filterOptions, sortOption);
  }
}
  
}, [client, refresh, isSameCardsModalOpen, talentProfile, whatModal,filterOptions, sortOption]);

useEffect(() => {
  const fetchAssets = async () => {
    try {
      if (client && walletAddress) {
        let assetCursor;
        let fetchedAssets: Asset[] = [];

        do {
          const assetRequest: AssetResponse = await client.getAssets({
            user: walletAddress,
            cursor: assetCursor,
          });

          fetchedAssets = fetchedAssets.concat(assetRequest.result);
          assetCursor = assetRequest.cursor;
        } while (assetCursor);

        setAssets(fetchedAssets);
      }
    } catch (error) {
      console.error('Error fetching assets:', error);
    }
  };
  if (client && walletAddress) {
    fetchAssets();
  }
}, [client, walletAddress, filterOptions, sortOption]);

useEffect(() => {
  // Define an async function to fetch the ETH balance
  async function fetchEthBalance(walletAddress: any) {
    try {
      const response = await axios.get(`https://api.sandbox.x.immutable.com/v2/balances/${walletAddress}`);
      
      if (response.data && response.data.result && response.data.result.length > 0) {
        const ethBalanceHex = response.data.result[0].balance;
        const ethBalanceDecimal = hexToDecimal(ethBalanceHex, 18); // Assuming 18 decimals for ETH
        localStorage.setItem('pxlBalance',ethBalanceDecimal.toString());
        
        return ethBalanceDecimal;
      }
      
      return null; // Return null if the response does not contain the balance
    } catch (error) {
      console.error('Error fetching ethBalance:', error);
      return null; // Return null in case of an error
    }
  }

  function hexToDecimal(hex: string, decimals: number): number {
    const hexValue = BigInt(hex);
    const divider = BigInt(10 ** decimals);
    return Number(hexValue) / Number(divider);
  }

  // Fetch ETH to USD and ETH to EUR conversion rates when selectedCurrency changes
  async function fetchEthToCurrencyConversion() {
      try {
       
        const usdRateString = localStorage.getItem('usdRate');
        const eurRateString = localStorage.getItem('eurRate');

        // Convert string to number using parseFloat. Use 0 or any default value as fallback if null
        const ethToUsdRate = usdRateString ? parseFloat(usdRateString) : 1;
        const ethToEurRate = eurRateString ? parseFloat(eurRateString) : 1;

      const WalletBalance = localStorage.getItem('walletAddress');

        if (selectedCurrency === 'USD' && ethToUsdRate !== undefined) {
        // Fetch the ETH balance using the wallet address
        const ethBalance: number = WalletBalance ? await fetchEthBalance(WalletBalance) ?? 0 : 0;

        localStorage.setItem('pxlBalance',ethBalance.toString());

       
        balancePush(ethBalance);
        

        if (ethBalance !== null) {
          const amountInDollars = ethBalance * ethToUsdRate;
          
          setEthBalanceInDollars(amountInDollars);
          setEthBalance(ethBalance);
          localStorage.setItem('pxlBalance',ethBalance.toString());
      
          balancePush(ethBalance);
        }
      } else if (selectedCurrency === 'EUR' && ethToEurRate !== undefined) {
        // Fetch the ETH balance using the wallet address
        const ethBalance: number = WalletBalance ? await fetchEthBalance(WalletBalance) ?? 0 : 0;
        localStorage.setItem('pxlBalance',ethBalance.toString());
     
        balancePush(ethBalance);

        if (ethBalance !== null) {
          const amountInEur = ethBalance * ethToEurRate;
          setEthBalanceInDollars(amountInEur);
          setEthBalance(ethBalance);
          localStorage.setItem('pxlBalance',ethBalance.toString());
     
          balancePush(ethBalance);
        }
      }
    } catch (error) {
      console.error('Error fetching ETH conversion rate:', error);
    }
  }

  // Call the fetchEthToCurrencyConversion function
  fetchEthToCurrencyConversion();
  setIsWalletMenuOpen(false);
}, [selectedCurrency]); 

useEffect(() => {
  // Function to fetch the total score
  const fetchTotalScore = async () => {
    try {
      const walletAddress = localStorage.getItem('walletAddress');
      const response = await fetch(`${linkUrl}/wp-json/custom/v1/get-total-score/?walletAddress=${walletAddress}`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setTotalScore(data.total_score);
      setTotalLineup(data.total_lineup);
      setAutoKeep(data.auto_keep_lineup);
      console.log('totalScore', data.total_score);
    } catch (error) {
      console.error('Failed to fetch total score:', error);
      setTotalScore(null); // Reset on error
      setTotalLineup(null);
      setAutoKeep('0');
    }
  };

  // Only fetch if activeState is 'myLineup'
  if (activeSection === 'myLineup') {
    fetchTotalScore();
  }
}, [activeSection, refresh]);

useEffect(() => {
  setHandleRefreshFunc(handleRefresh); // Set the handleRefresh function
}, []);

useEffect(() => {
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

useEffect(() => {
  // Get the scrollable element, default to window if no scrollable container is found
  const scrollableElement = document.querySelector('#tokensAllAuctions') || window;

  const handleScroll = () => {
    let currentScrollTop = 0;

    if (scrollableElement instanceof Window) {
      // Use scrollY for window scrolling
      currentScrollTop = window.scrollY;
    } else if (scrollableElement instanceof Element) {
      // Use scrollTop for elements
      currentScrollTop = scrollableElement.scrollTop;
    }

    if (currentScrollTop > lastScrollTop) {
      // Scrolling down: reduce opacity
      setOpacity(0.3); // You can adjust this value for more or less transparency
    } else {
      // Scrolling up: increase opacity
      setOpacity(0.75); // Fully visible
    }

    lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // Prevent negative values
  };

  scrollableElement.addEventListener('scroll', handleScroll);

  return () => {
    scrollableElement.removeEventListener('scroll', handleScroll); // Clean up on unmount
  };
}, []);

useEffect(() => {
  const handleScroll = throttle(() => {
    // Your scroll-related logic here
  }, 100); // Fires every 100ms

  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);



return (

<div className="App" key={refresh}>

{(onGame === false && onTalentAdv === false) && (
         <div className={`sticky-header-onboard ${isScrolled ? 'scrolled' : 'initial'}`}>
         <a href={`${linkUrl}`}><img className="sticky-logo-onboard" src={`${linkUrl}/wp-content/uploads/2024/06/logo_peaxel_1000x200_transparent-3.png`} alt="Peaxel Logo" /></a>
         <div id="TalentCall">
         <span className="talentSignUp" style={{ cursor: 'pointer' }} onClick={openTalentSignupModal}>
  {t ('iAmATalent')}
</span>
          </div>
        </div>
      )}

{(onGame === false && onTalentAdv === true) && (
         <div className={`sticky-header-onboard ${isScrolled ? 'scrolled' : 'initial'}`}>
         <a href={`${linkUrl}`}><img className="sticky-logo-onboard" src={`${linkUrl}/wp-content/uploads/2024/06/logo_peaxel_1000x200_transparent-3.png`} alt="Peaxel Logo" /></a>
         <div id="TalentCall">
         <span className="talentSignUp" style={{ cursor: 'pointer' }} onClick={() => { handleTalentSignupOpen(); }}>        
          {t('applyNow')}
            </span>
          </div>
        </div>
      )}


{isWelcomeModalOpen && <Suspense fallback={<div>{t('loading')}</div>}><WelcomeModalLazy onClose={handleCloseWelcomeModal} /></Suspense>} {/* Render the modal if needed */}

{isTalentSignupModalOpen && <Suspense fallback={<div>{t('loading')}</div>}><TalentSignupModalLazy onClose={handleTalentSignupClose} /></Suspense>}

<Suspense fallback={<div>{t('loading')}</div>}>
<PageViewModalLazy 
        isVisible={isPageViewModalVisible} 
        onClose={() => closePageViewModal()} 
        asset={assetProfileView}
        dispCurrency={selectedCurrency}
        handleProfileButtonClick={handleProfileButtonClick}
      />
</Suspense>

  <Suspense fallback={<div>{t('loading')}</div>}>
  {selectedTalent && (
    <ModalSameCardsLazy 
      assetName={selectedTalent.assetName} 
      onClose={closeSameCardsModal} 
      refresh={refresh}
      activeSection={activeSection}
    />
    )}
    </Suspense>


<Suspense fallback={<div>{t('loading')}</div>}>
  {selectedAsset && (
    <AssetModalLazy asset={selectedAsset.asset} saleID={selectedAsset.order_id} onClose={closeAssetModal} locationPath={whatPage} activeSection={activeSection} />
  )}
 
  {selectedOrder && selectedOrder.order && (
    <OrderModalLazy 
      order={selectedOrder.order} 
      onClose={closeOrderModal}
      exchangeRates={selectedOrder.exchangeRates}
      currency={selectedOrder.currency as "USD" | "EUR"}
      locationPath={whatPage}
      activeSection={activeSection}
    />
  )}
</Suspense>



{(onGame === true && (whatPage === 'dashboard' || whatPage === 'team' || whatPage === 'market' || whatPage === 'competition')) && (
<div id="GlobalPeaxel">


{(onGame === true && (whatPage === 'market' || whatPage === 'team' )) && (
(activeSection === 'allMyTalents' 
  || activeSection === 'listedTalents' 
  || activeSection === 'allMyCards' 
  || activeSection === 'allTalents'
  || activeSection === 'allAuctions'
  || activeSection === 'managersSales' 
  || activeSection === 'offersReceived'
  || activeSection === 'offersSent' )) && (
    <>
<div id="tokenContainerAllAuctions" style={{ position: 'fixed' }}>

<div id="stage1"> 

<span className="filters-actions">

{isMobileConst=== false && (
<span className="filter-button" onClick={toggleFilterModal}>
  <i className="fa-solid fa-sliders"></i>&nbsp;&nbsp;
  {isFilterModalOpen ? t('hideFilters') : t('showFilters')}
</span> 
)}

{isMobileConst=== true && (
<span className="filter-button" onClick={toggleFilterModal}>
  <i className="fa-solid fa-sliders"></i>&nbsp;&nbsp;
  {isFilterModalOpen ? t('results') : t('filters')}
</span> 
)}

{!isFilterEmpty() && (
<span
  className="filter-button"
  onClick={clearAllFilters}
  >

{isMobileConst ? t('clear') : t('clearFilters')}&nbsp;<i className="fa-solid fa-xmark"></i>
          </span>
)}
</span>

<span className="sort-options">
  <select 
    className="selector-button" 
    onChange={(e) => handleSortChange(e.target.value)}
    value={sortOption} >
    <option value="newest">{t('newest')}</option>
    <option value="oldest">{t('oldest')}</option>
    <option value="lowestPrice">{t('lowestPrice')}</option>
    <option value="highestPrice">{t('highestPrice')}</option>
  </select>
</span>

</div>

<div id="stage2">

{isFilterModalOpen && (  
  <Suspense fallback={<div>{t('loading')}</div>}>
        <FilterModalLazy
          isOpen={isFilterModalOpen}
          onClose={toggleFilterModal}
          onApplyFilters={applyFilters}
          currentOptions={filterOptions}
          selectedCurrency={selectedCurrency ?? ''}
        />
        </Suspense>     
      )}

{ (onGame === true && (activeSection ==="allMyTalents"  )) && (
  <div id="tokensteamshort" ></div>
  )}

{ (onGame === true && (activeSection ==="allMyCards"  )) && (
  <div id="tokenscardsportfolio" ></div>
  )}
  
{ (onGame === true && (activeSection === 'listedTalents' 
  || activeSection === 'allTalents'
  || activeSection === 'allAuctions'
  || activeSection === 'managersSales' 
  || activeSection === 'offersReceived'
  || activeSection === 'offersSent'  )) && (
<div id="tokensAllAuctions"></div>
)}

</div>



</div>
</>
)}

{(onGame === true && (activeSection === 'talentNews')) && (
  <Suspense fallback={<div>{t('loading')}</div>}>
    <LastNewsDisplayLazy 
    limit={20}
    onNewsCountChange={handleNewsCount}
    />
  </Suspense>
)}


{(onGame === true && (activeSection === 'overView' 
  || activeSection === 'myLineup' || activeSection === 'insights')) && (
<>
<div id="tokenContainerAllAuctions">

<div id="stage21" className={activeSection === 'myLineup' ? 'stage21Effect' : ''}>
 
  { (activeSection === 'insights') && (
    <div className="image-container-dashboard">
   
   <div style={{ position: 'relative', textAlign: 'center' }}>
   <video 
    onClick={handleMarketLinkClick}
    src={`${linkUrl}/wp-content/uploads/2024/10/1.mp4`} 
    autoPlay 
    muted 
    playsInline
    style={{ width: '100%', maxHeight: '500px', objectFit: 'cover', 
      pointerEvents: 'none' }} 
   />

<h1 style={{
        position: 'absolute',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '300%',
        color: 'white',
        textShadow: 'rgba(0, 0, 0, 0.5) 2px 2px 4px',
        width: '90%',
        fontWeight: '700'
    }}>
        {t ('scoutTopTalents')}
    </h1>
</div>

<div style={{ position: 'relative', textAlign: 'center' }}>
    <video 
        onClick={() => handleLinkClick('team','allMyCards')}
        src={`${linkUrl}/wp-content/uploads/2024/10/2.mp4`} 
        autoPlay 
        muted 
        playsInline
        style={{ width: '100%', maxHeight: '500px', objectFit: 'cover', 
          pointerEvents: 'none' }} 
    />

<h1 style={{
        position: 'absolute',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '300%',
        color: 'white',
        textShadow: 'rgba(0, 0, 0, 0.5) 2px 2px 4px',
        width: '90%',
        fontWeight: '700'
    }}>
        {t ('buildYourTeam')}
    </h1>
</div>

<div style={{ position: 'relative', textAlign: 'center' }}>
    <video 
        onClick={() => handleLinkClick('competition','myLineup')}
        src={`${linkUrl}/wp-content/uploads/2024/10/3.mp4`} 
        autoPlay 
        muted 
        playsInline
        style={{ width: '100%', maxHeight: '500px', objectFit: 'cover', 
          pointerEvents: 'none' }} 
    />

<h1 style={{
        position: 'absolute',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '300%',
        color: 'white',
        textShadow: 'rgba(0, 0, 0, 0.5) 2px 2px 4px',
        width: '90%',
        fontWeight: '700'
    }}>
        {t ('lineupForVictory')}
    </h1>
</div>
  
   
    </div>
  )}


<div className="image-container-dashboard">

{(activeSection === 'insights') && (
  <div id="titleStats">
    <div className="focusTitle">
        <i style={{ opacity: '0.5', fontSize: '75%' }} className="fa-solid fa-users-rectangle"></i> {' '}
        &nbsp;{teamName}
      </div>
    <div className="teamStatistics">
      <div id="teamStats1" onClick={() => handleLinkClick('competition', 'myLineup')}></div>
      <div id="teamStats2" onClick={() => handleLinkClick('team', 'allMyTalents')}></div>
      <div id="teamStats3" onClick={() => handleLinkClick('team', 'allMyCards')}></div>
      <div id="teamStats4" onClick={() => handleLinkClick('market', 'listedTalents')}></div>
    </div>
  </div>
)}

{(activeSection === 'insights') && (
  <div id="titleStats">  
  <div className="focusTitle">
        <i style={{ opacity: '0.5', fontSize: '75%' }} className="fa-solid fa-chart-line"></i> {' '}
        &nbsp;{t('myTeamValue')}
      </div>
  
     
  <div className="currentValue">
    {`${latestValue} ${localStorage.getItem('pxlCurrency') || 'USD'}`}
  </div>

      <Suspense fallback={<div>{t('loading')}</div>}>
      <ChartValueTeamLazy />
      </Suspense>

  </div>
)}

{(activeSection === 'insights') && (
  <div id="titleStats">
    <div className="focusTitle">
        <i style={{ opacity: '0.5', fontSize: '75%' }} className="fa-solid fa-landmark"></i> {' '}
        &nbsp;{t('TotalBalance')}
      </div>
   
      <div className="currentValue">
    {`${latestBalance} ${localStorage.getItem('pxlCurrency') || 'USD'}`}
  </div>

      <Suspense fallback={<div>{t('loading')}</div>}>
      <DailyBalanceChartLazy />
      </Suspense>
    
  </div>
)}



</div>

{activeSection === "overView" && (
  <>

      <div id="titleStats">
        <div style={{
          color: '#FAFAFA',
          width: '100%',
          fontWeight: '700',
          fontSize: '2em',
          padding: '20px 0 20px 20px'
        }}>
          <i style={{ opacity: '0.5', fontSize: '75%' }} className="fa-solid fa-users-rectangle" /> {' '}
          &nbsp;{teamName}
        </div>
        <div className="teamStatistics">
          <div id="teamStats1" onClick={() => handleLinkClick('competition', 'myLineup')} />
          <div id="teamStats2" onClick={() => handleLinkClick('team', 'allMyTalents')} />
          <div id="teamStats3" onClick={() => handleLinkClick('team', 'allMyCards')} />
          <div id="teamStats4" onClick={() => handleLinkClick('market', 'listedTalents')} />
        </div>
      </div>

    <div className="moduleOverview">
      <div style={{
        color: '#FAFAFA',
        width: '100%',
        fontWeight: '700',
        fontSize: '2em',
        padding: '20px 0 20px 20px'
      }}>
        <i style={{ opacity: '0.5', fontSize: '75%' }} className="fa-solid fa-rotate" /> {' '}
        &nbsp;{t('transfers')}
      </div>
      <div className="teamStatistics">
        
          <Suspense fallback={<div>{t('loading')}</div>}>
          <TeamTurnoverLazy />
          </Suspense>
      
      </div>
    </div>
  </>
)}







{(onGame === true && activeSection === "myLineup") && (
 

 <div className="image-container-dashboard">
   
   <div style={{ position: 'relative', textAlign: 'center' }}>
   <video 
    onClick={handleMarketLinkClick}
    src={`${linkUrl}/wp-content/uploads/2024/10/lineup_left_side_pi.mp4`} 
    autoPlay 
    muted 
    playsInline
    style={{ width: '100%', maxHeight: '500px', objectFit: 'cover', 
      pointerEvents: 'none' }} 
   />

{isMobileConst === true && (
<h1 style={{
        position: 'absolute',
        bottom: '5%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '200%',
        color: 'white',
        textShadow: 'rgba(0, 0, 0, 0.5) 2px 2px 4px',
        width: '90%',
        fontWeight: '700'
    }}> 
        <Suspense fallback={<div>{t('loading')}</div>}>
        <EventCountdownLazy
        currentState= {activeSection}
        selectedGameweek={selectedGameweek}
        onGameweekChange={handleGameweekChange}
      />
        
        </Suspense>
    </h1>
)}
{isMobileConst === false && (
<div id="gamedesk" style={{
        position: 'absolute',
        bottom: '5%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '200%',
        color: 'white',
        textShadow: 'rgba(0, 0, 0, 0.5) 2px 2px 4px',
        width: '90%',
        fontWeight: '700'
    }}> 
        <Suspense fallback={<div>{t('loading')}</div>}>
        <EventCountdownLazy
        currentState= {activeSection}
        selectedGameweek={selectedGameweek}
        onGameweekChange={handleGameweekChange}
      />
        </Suspense>
</div>
)}
</div>

<div 
className="lineup-description" style={{ position: 'relative', overflow: 'hidden' }}>
  
  {isMobileConst === false && (
  <video
  src={`${linkUrl}/wp-content/uploads/2024/10/lineup_left_side_pi-1.mp4`}
  autoPlay
  muted
  playsInline
  style={{
    position: 'absolute',
    top: 0,
    right: 0, // Aligns the video to the right
    height: '100%', // Sets video height to fill the parent container
    objectFit: 'cover', // Maintains aspect ratio and fills the height
    pointerEvents: 'none', // Prevents interaction
    zIndex: -1 // Places video behind other elements
  }}
/>
)}

<div id="howToPlay" style={{
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignContent: 'flex-start',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  rowGap: '15px',
  display: isHowToPlayVisible ? 'flex' : 'none', // Show/hide based on state
  transition: 'all 0.3s ease',
  marginBottom: '-30px',
  marginTop: '30px',
  fontSize: '85%'
}}>
<span
style={{
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  columnGap: '10px'
}}><span style={{color: '#93003F', marginRight: '10px', opacity: '0.8'}}><i className="fa-solid fa-id-badge"></i></span><span>{t('lineupRule1')}</span></span>
<span
style={{
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  columnGap: '10px'
}}><span style={{color: '#93003F', marginRight: '10px', opacity: '0.8'}}><i className="fa-solid fa-calendar-days"></i></span><span>{t('lineupRule2')}</span></span>
<span
style={{
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  columnGap: '10px'
}}><span style={{color: '#93003F', marginRight: '10px', opacity: '0.8'}}><i className="fa-solid fa-chart-simple"></i></span><span>{t('lineupRule3')}</span></span>
<span
style={{
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  columnGap: '10px'
}}><span style={{color: '#93003F', marginRight: '10px', opacity: '0.8'}}><i className="fa-solid fa-binoculars"></i></span><span>{t('lineupRule4')}</span></span>
</div>

<div id="glob">

{isMobileConst === false && (
<span className="lineupTitle">Lineup</span>
)}

<span className="totalScoreLineup"
style={{
  fontSize: '175%',
  fontWeight: '900',
  color: 'rgb(177, 199, 26)',
  width: 'auto',
  textAlign: 'center',
  display: 'flex',
  flexFlow: 'row',
  placeContent: 'center',
  alignItems: 'flex-start',
  padding: '3px 12px',
  background: 'rgb(0, 0, 0, 0.6)',
  border: '1px solid rgb(65, 65, 65)',
  borderRadius: '3px',
  marginBottom: '16px',
}}><NumberFormatter number={totalScore || '0'} /> &nbsp;{t('points')}</span>

<div style={{
  fontSize: '90%',
  fontWeight: '500',
  color: '#ffffff',
  width: 'auto',
  textAlign: 'center',
  display: 'flex',
  flexFlow: 'row',
  placeContent: 'center',
  alignItems: 'center',
  marginBottom: '18px',
    alignContent: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    columnGap: '10px'
}}>
<span 
style={{
  width: 'auto',
  textAlign: 'center',
  display: 'flex',
  flexFlow: 'row',
  placeContent: 'center',
  alignItems: 'center',
  padding: '3px 12px',
  background: '#ffffff14',
  border: '1px solid rgba(255, 255, 255, 0.23)',
  borderRadius: '30px',
    alignContent: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'center',
}}>{totalLineup ? (parseInt(totalLineup) > 1 ? `${totalLineup} ${t('Cards')}` : `${totalLineup} ${t('Card')}` ) : 0} </span>

{autoKeep === '1' && (
  <span 
  style={{
    width: 'auto',
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'row',
    placeContent: 'center',
    alignItems: 'center',
    padding: '3px 12px',
    background: '#ffffff14',
    border: '1px solid rgba(255, 255, 255, 0.23)',
    borderRadius: '30px',
      alignContent: 'center',
      flexWrap: 'nowrap',
      justifyContent: 'center',
  }}>Auto-Keep</span>
)}







</div>

<div id="globo"
style={{
  display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    columnGap: '10px',
    fontSize: '75%',
    opacity: '0.9'
}}>
<div>
{isUnlocked === "unlocked" ? <i className="fa-solid fa-unlock"></i> : <i className="fa-solid fa-lock"></i>}&nbsp;&nbsp;
{isUnlocked === "unlocked" ? t('Unlock') : t('Locked')}</div>
<div
style={{
  color: '#FFFFFF',
  fontWeight: '400',
  backgroundColor: '#00000051',
  padding: '5px 10px',
  marginBottom: '15px',
  textAlign: 'center',
  border: '1px solid #4f4f4f51',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '100%'
}}
onClick={toggleHowToPlay}>
<i className="fa-solid fa-circle-question"></i>&nbsp;&nbsp;{t('howToPlay')}</div>
</div>



</div>
</div>

  
   
</div>



)}



  { (onGame === true && (activeSection ==="myLineup")) && (

<>
<div id="tokensLineup"></div>

<div className="myLineupOptions">
<Suspense fallback={<div>{t('loading')}</div>}>
<KeepLineupCheckedLazy /> 
</Suspense>

<Suspense fallback={<div>{t('loading')}</div>}>
<PastLineupsLazy />
</Suspense>
</div>

  </>

  )}
  
  { (onGame === true && (activeSection === 'insights')) && (
  <div id="titleMarket"></div>
  )}
  { (onGame === true && (activeSection === 'insights')) && (
  <div id="marketStats"></div>
  )}
  
 </div>  
 

<div id="stage3">

{(onGame === true) && (
  <div style={{
    paddingBottom: '100px'
  }}>
  <Suspense fallback={<div>{t('loading')}</div>}><FooterLazy /></Suspense></div>
  )}

</div> 
</div>
</>
)}

</div>
)}

{(onGame === true && activeSection === 'results') && (
  <div id="GlobalPeaxel">
    
    <div className="globalCompetition">
  
    <div className="image-container-competition">
  
   <div className="competition-video">
    <video 
        onClick={() => handleLinkClick('market', 'allTalents')}
        src={`${linkUrl}/wp-content/uploads/2024/10/intro_championship_PXL_1.mp4`} 
        autoPlay 
        muted 
        playsInline
        style={{ width: '100%', maxHeight: '1000px', objectFit: 'cover', 
          pointerEvents: 'none' }} 
    />

{isMobileConst === false && (
  <>
  <h1 style={{
        position: 'absolute',
        top: '100px',
        left: '30%',
        transform: 'translateX(-50%)',
        fontSize: '400%',
        color: 'white',
        textShadow: 'rgba(0, 0, 0, 0.5) 2px 2px 4px',
        width: '90%',
        fontWeight: '700'
    }}>
        Elite Arena

        <div>
        <Suspense fallback={<div>{t('loading')}</div>}>
        <EventCountdownLazy
        currentState= {activeSection} 
        selectedGameweek={selectedGameweek}
        onGameweekChange={handleGameweekChange}
      />
        </Suspense>
        </div>
 
    </h1>
  
        </>
  
  )}
{isMobileConst === true && (
  <>
  <h1 style={{
        position: 'absolute',
        top: '40px',
        paddingLeft: '5%',
        fontSize: '250%',
        color: 'white',
        textShadow: 'rgba(0, 0, 0, 0.5) 2px 2px 4px',
        width: '90%',
        fontWeight: '700',
        textAlign: 'left',
        left:'12%'
    }}>
        Elite Arena
        <div>
        <Suspense fallback={<div>{t('loading')}</div>}>
        <EventCountdownLazy
        currentState= {activeSection}
        selectedGameweek={selectedGameweek}
        onGameweekChange={handleGameweekChange}
      />
        </Suspense>
        </div>
    </h1>
  
  
        </>)}
</div>
<div className="EFAStats">
      <div style={{ color: '#ffffff', width: '100% !important', fontWeight: '400', fontSize: '1.3em', paddingTop: '20px' }}>
        <i style={{ opacity: '0.5', fontSize: '75%' }} className="fa-solid fa-bars-progress"></i>
        <span style={{
          fontSize: '85%',
          fontWeight: '500',
        }}>&nbsp;{selectedGameweek === mainGameweek ? <span>{t('inProgress')}&nbsp;<i style={{color:'#c9e410', fontSize:'80%'}} className="fa-solid fa-circle"></i></span> : <span>{t('finished')}&nbsp;<i  style={{color:'#62002a', fontSize:'80%'}} className="fa-solid fa-square"></i></span>}
     </span>
      </div>
<div style={{ color: '#ffffff', width: '100% !important', fontWeight: '400', fontSize: '1.3em', paddingTop: '20px' }}>
        <i style={{ opacity: '0.5', fontSize: '75%' }} className="fa-solid fa-ranking-star"></i>
        <span style={{
          fontSize: '85%',
          fontWeight: '500',
        }}>&nbsp;{t('Position')}&nbsp;{userPosition}</span>
      </div>
<div style={{ color: '#ffffff', width: '100% !important', fontWeight: '400', fontSize: '1.3em', paddingTop: '20px', paddingBottom: '20px' }}>
        <i style={{ opacity: '0.5', fontSize: '75%' }} className="fa-solid fa-users-rectangle"></i>
        <span style={{
          fontSize: '85%',
          fontWeight: '500',
        }}>&nbsp;{distinctOwnersCount}&nbsp;{t('teams')}</span>
      </div>

  </div>

</div>

<Suspense fallback={<div>{t('loading')}</div>}>
<RankingDisplay
        selectedGameweek={selectedGameweek}
        onUserPositionChange={handleUserPositionChange}
        onDistinctOwnersCountChange={handleDistinctOwnersCountChange}
      />
</Suspense>

</div> 
</div>
)}

{(isMobileConst === true && onGame === true && isPageViewModalVisible === false) && (
 <div className="sticky-bottom-menu" style={{ opacity }}>
        <div className="bottom-menu">
        <div className="distrib" 
           onClick={() => handleLinkClick('dashboard','insights')}>
            <i className="fa-solid fa-chart-simple"></i>
            {whatPage !== 'dashboard' && (
            <span>{t('dashBoard')}</span>
          )}
          {whatPage === 'dashboard' && (
            <span style={{fontWeight:700}}>{t('dashBoard')}</span>
          )}
          </div>
          <div className="distrib" 
           onClick={() => handleLinkClick('market','allTalents')}>
            <i className="fa-regular fa-id-badge"></i>
            {whatPage !== 'market' && (
            <span>{t('market')}</span>
          )}
          {whatPage === 'market' && (
            <span style={{fontWeight:700}}>{t('market')}</span>
          )}
          </div>
          <div className="distrib" 
           onClick={() => handleLinkClick('team','overView')}>
            <i className="fa-solid fa-users-rectangle"></i>
            {whatPage !== 'team' && (
            <span>{t('team')}</span>
          )}
          {whatPage === 'team' && (
            <span style={{fontWeight:700}}>{t('team')}</span>
          )}
          </div>
          <div className="distrib" 
           onClick={() => handleLinkClick('competition','myLineup')}>
            <i className="fa-solid fa-bolt-lightning"></i>
          {whatPage !== 'competition' && (
            <span>{t('competition')}</span>
          )}
          {whatPage === 'competition' && (
            <span style={{fontWeight:700}}>{t('competition')}</span>
          )}
          </div>
          
        </div>
      </div>
)}



<Suspense fallback={<div>{t('loading')}</div>}>
    {(shouldRenderHeader && onGame === true )&& (
<OptimizedHeaderLazy
  hasWallet={hasWallet}
  wallet={wallet || ''} // Provide a default empty string if wallet is null or undefined
  ethBalanceInDollars={ethBalanceInDollars}
  selectedCurrency={selectedCurrency || 'USD'} // Provide a default value if selectedCurrency is null
  isWalletMenuOpen={isWalletMenuOpen}
  handleToggleWalletMenu={handleToggleWalletMenu}
  activeSection={activeSection} 
  setActiveSection={setActiveSection}
  whatPage={whatPage}
  setWhatPage={setWhatPage}
  currentURLState={currentURLState}
  setCurrentURLState={setCurrentURLState}
  onGame={onGame}
  handleLinkClick={handleLinkClick}
/>
 
)}
</Suspense>

</div>

 
);
};

export default App;